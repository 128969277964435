import * as React from "react";
import { TextField } from "./";
import { Calendar } from "./icons";
import { TextFieldProps } from "@mui/material";
import { DatePicker as MaterialDatePicker, DatePickerProps as MaterialDatePickerProps } from "@mui/x-date-pickers-pro";
import { Moment } from "moment";

interface DatePickerProps extends Omit<MaterialDatePickerProps<Moment, Moment>, 'renderInput'> {
    renderInput?: (props: TextFieldProps) => React.ReactElement;
    placeholder?: string;
}

const DatePicker: React.FunctionComponent<DatePickerProps> = React.forwardRef((props: DatePickerProps, ref: any) =>
    <div className="date-picker-container">
        <MaterialDatePicker
            ref={ref}
            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
            components={{
                OpenPickerIcon: Calendar
            }}
            inputFormat="YYYY/MM/DD"
            mask="____/__/__"
            {...props}
        />
    </div>
);

export default DatePicker;