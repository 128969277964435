import { connect } from "react-redux";
import { showSuccess, showError } from "../../store/notifications/actions";
import { updateParameterValue } from "../../store/storyline/actions";
import { RootState } from "../../store/index";
import _ from "lodash";

interface ParameterValuesProps {
    parameterValues: Map<string, any>,
    updateParameterValue: typeof updateParameterValue,
    showSuccess: typeof showSuccess,
    showError: typeof showError
}

function ParameterValues(props: ParameterValuesProps) {
    const { parameterValues } = props;

    return (
        <>
            <div className="screen-centered">
                <table className="table">
                    <tbody>
                        {
                            _.map(Array.from(parameterValues.entries()), entry => <ParameterValueRow key={entry[0]} entry={entry} />)
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

interface ParameterValueRowProps {
    entry: [string, any]
}

function ParameterValueRow(props: ParameterValueRowProps) {
    const { entry } = props;

    return (
        <tr>
            <td className="heading-cell">{entry[0]}</td>
            <td><pre>{typeof entry[1] === "object" ? JSON.stringify(entry[1], null, 2) : String(entry[1])}</pre></td>
        </tr>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any, showSuccess, showError })(ParameterValues);