import React from 'react';
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { Checkbox as BaseCheckbox } from "../../../shared/components";

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
}

export function Checkbox(props: Props) {
    const { storyline, updateParameterValue, name, ...rest } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || false);

    const handleChange = (event: any) => {
        updateParameterValue(name, event.target.checked);
        setValue(event.target.checked);
    };

    React.useEffect(() => {

        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }

    }, [storyline.parameterValues]);

    return (
        <BaseCheckbox
            {...rest}
            checked={value}
            onChange={handleChange}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }} />
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(Checkbox);