import React from "react";
import DownloadStoryline from "./DownloadStoryline";
import FeedbackWidget from "./FeedbackWidget";


export default function StorylineMenu(props: any) {
    return <>
        <FeedbackWidget />
        <DownloadStoryline />
    </>;
}