import "./HeatmapTable.scss";
import * as _ from "lodash";
import clsx from "clsx";
import { ContentRenderer } from "../ContentRenderer";

interface HeatmapTableCell {
    foregroundColor?: string,
    backgroundColor?: string,
    content: string
}

interface HeatmapTableColumn {
    title: string;
    children?: HeatmapTableColumn[];
}

interface HeatmapTableRow {
    title: string;
    cells: HeatmapTableCell[]
}

interface HeatmapTableProps {
    columns: HeatmapTableColumn[];
    rows: HeatmapTableRow[]
    className?: string;
}

interface FlattenedColumn {
    column: HeatmapTableColumn;
    depth: number;
}

function getFlattenedColumns(columns: HeatmapTableColumn[]): FlattenedColumn[] {
    let result = [];

    function flattenColumn(column: HeatmapTableColumn, currentDepth: number) {
        result.push({
            column,
            depth: currentDepth
        });

        column?.children?.forEach(c => flattenColumn(c, currentDepth + 1));
    }

    columns.forEach(c => flattenColumn(c, 1));
    return result;
}

function getColumnSpan(column: HeatmapTableColumn) {
    let result = 0;

    function populateColSpan(column: HeatmapTableColumn) {
        if (!column?.children?.length) {
            result++;
        }
        else {
            column.children.forEach(populateColSpan);
        }
    }

    populateColSpan(column);
    return Math.max(1, result);
}

function HeatmapTable(props: HeatmapTableProps) {
    const { columns, rows, className } = props;
    const flattenedColumns = getFlattenedColumns(columns);
    const headerDepth = flattenedColumns.reduce((acc, elem) => Math.max(acc, elem.depth), 0);

    return (
        <table className={clsx("fill", "table", "compact", "heatmap-table", className)}>
            <thead>
                {
                    Array.from(Array(headerDepth).keys()).map(currentDepth =>
                        <tr>
                            {currentDepth === 0 && <th rowSpan={headerDepth}></th>}
                            {flattenedColumns.filter(c => c.depth === currentDepth + 1).map(c => <th colSpan={getColumnSpan(c.column)}>{c.column.title}</th>)}
                        </tr>
                    )
                }
            </thead>
            <tbody>
                {
                    rows.map(row => <tr>
                        <th className="font-weight-normal">
                            {row.title}
                        </th>
                        {
                            row.cells.map(cell =>
                                <td style={{ backgroundColor: cell.backgroundColor, color: cell.foregroundColor }}>
                                    <ContentRenderer className="data-cell" input={cell.content} />
                                </td>)
                        }
                    </tr>)
                }
            </tbody>
        </table>
    );
}

HeatmapTable.defaultProps = {
    columns: [],
    rows: []
};

export default HeatmapTable;