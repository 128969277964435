import * as React from "react";
import { Option } from "../../Autocomplete";
import { Button } from "../../Button";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import _ from "lodash";
import { Dialog, DialogActions, DialogContent, DialogTitleWithCloseButton, TextField } from "../../../../shared/components";

interface CreateDialogProps {
    entityName: string;
    entities: Option[];
    acceptCallback: (args: Object) => void;
    closeCallback: () => void;
}

function CreateDialog(props: CreateDialogProps) {
    const { entityName, entities, acceptCallback, closeCallback } = props;
    const schema = object().shape({
        name: string()
            .trim()
            .required(`Please supply a name for the new ${entityName}.`)
    });
    const { register, handleSubmit, errors, setError } = useForm({ resolver: yupResolver(schema) });

    const save = React.useCallback((formModel: { name: string }) => {
        if (_.find(entities, e => e.label === formModel.name)) {
            setError("name", { message: `'${formModel.name}' is an existing ${entityName} Name!  Please choose a different name.`, type: "validation_error" });
            return;
        }

        acceptCallback({
            name: formModel.name
        });
    }, [entities, acceptCallback]);

    return (
        <Dialog
            open={true}
            onClose={(_event, reason) => {
                if (reason !== 'backdropClick') {
                    closeCallback();
                }
            }}
            aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeCallback} id="form-dialog-title">
                Create New {entityName}
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)} autoComplete="off">
                <DialogContent>
                    <div className="vertical-form-field">
                        Please supply a name for the new {entityName}:
                    </div>
                    <TextField
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputRef={register}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="text" color="warning">
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export { CreateDialog };