import "./KpiDelta.scss";
import clsx from "clsx";
import { ContentRenderer } from "../ContentRenderer";
import { ProgressBar } from "../../../shared/components";
import * as _ from "lodash";

interface KpiDeltaProps {
    title?: string;
    value?: string;
    delta?: string;
    progressBarValues?: [number, number];
    maxProgressBarValue?: number,
    className?: string;
}

function KpiProfile(props: KpiDeltaProps) {
    const { title, delta, value, progressBarValues, maxProgressBarValue, className, ...rest } = props;

    return (
        <div {...rest} className={clsx("kpi-delta", className)}>
            <div className="title">{<ContentRenderer input={title} />}</div>
            <div className="delta">{<ContentRenderer input={delta} />}</div>
            <div className="value">{<ContentRenderer input={value} />}</div>
            <div className="progressbar secondary">
                <ProgressBar value={_.max(progressBarValues)} maxValue={maxProgressBarValue} text=" " />
            </div>
            <div className="progressbar primary">
                <ProgressBar value={_.min(progressBarValues)} maxValue={maxProgressBarValue} text=" " />
            </div>
        </div>
    );
}

KpiProfile.defaultProps = {
    maxProgressBarValue: 1
};

export default KpiProfile;