import "./Chart.scss";
import React from "react";
import Plot, { PlotParams } from "react-plotly.js";
import useResizeAware from 'react-resize-aware';
import { connect } from "react-redux";
import { updateParameterValue } from "../../../store/storyline/actions";
import { throttle } from "lodash";
import { PlotRelayoutEvent } from "plotly.js";
import * as _ from "lodash";
import { RootState } from "../../../store";

const style = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

interface Props extends PlotParams {
    input: {
        data: Plotly.Data[];
        layout: Partial<Plotly.Layout>;
    };
    xAxisRangeParameters: string[];
    updateParameterValue: typeof updateParameterValue;
    parameterValues: Map<string, any>;
    navigateTo: (path: string) => void;
    staticPlot: boolean;
    disableResizeListener: boolean;
}

function Chart(props: Props) {
    const [resizeListener] = useResizeAware();
    const { input, xAxisRangeParameters, updateParameterValue, parameterValues, staticPlot, disableResizeListener, onClick, navigateTo, ...rest } = props;

    const dataPointClicked = React.useCallback(
        (data) => {
            // Gantt item selected...
            if (xAxisRangeParameters?.length === 2 && data?.points?.[0]?.base && data?.points?.[0]?.value) {
                // Set the xAxisRangeParameter values to the area of the selected item...
                if (parameterValues.get(xAxisRangeParameters[0]) !== data?.points?.[0]?.base || parameterValues.get(xAxisRangeParameters[1]) !== data?.points?.[0]?.value) {
                    updateParameterValue(xAxisRangeParameters[0], data?.points?.[0]?.base);
                    updateParameterValue(xAxisRangeParameters[1], data?.points?.[0]?.value);
                }
            }

            // Generic navigate-on-click handler...
            if (data?.points?.[0]?.pointIndex !== null && data?.points?.[0]?.pointIndex !== undefined) {
                const navigateToValue = data.points[0]?.data?.navigateTo?.[data?.points[0]?.pointIndex];
                if (navigateToValue) {
                    console.log(navigateToValue);
                    navigateTo?.(navigateToValue);
                }
            }
        },
        [navigateTo, updateParameterValue, xAxisRangeParameters, parameterValues]
    );

    // This memoized, throttled function persists the current axis bounds to parameter values.  This is only really useful 
    // for a date series, where these can be used as datasource parameters in order to highlight the focused data points...
    const persistAxisBoundsToParameters = React.useMemo(
        () =>
            throttle((e: PlotRelayoutEvent) => {
                if (!xAxisRangeParameters || xAxisRangeParameters?.length !== 2) return;

                // Both forms of the autorange value...
                if ((e?.["xaxis.autorange"] || e?.xaxis?.autorange)) {
                    _.forEach(xAxisRangeParameters, p => updateParameterValue(p, undefined));
                }
                // Parameter form 1 for explicit range...
                else if (e?.["xaxis.range[0]"] && e?.["xaxis.range[1]"]) {
                    for (let i = 0; i < xAxisRangeParameters.length; i++) {
                        updateParameterValue(xAxisRangeParameters[i], e[`xaxis.range[${i}]`]);
                    }
                }
                // Parameter form 2 for explicit range...
                else if (e?.["xaxis.range"]?.length === 2) {
                    for (let i = 0; i < xAxisRangeParameters.length; i++) {
                        updateParameterValue(xAxisRangeParameters[i], e["xaxis.range"][i]);
                    }
                }
            }, 1000),
        []);

    if (xAxisRangeParameters && parameterValues.get(xAxisRangeParameters[0]) && parameterValues.get(xAxisRangeParameters[1]) && props?.input?.layout?.xaxis) {
        input.layout.xaxis.range = [parameterValues.get(xAxisRangeParameters[0]), parameterValues.get(xAxisRangeParameters[1])];
    }

    // Ensure that chart sizes itself automatically based on the container width/height... 
    if (input?.layout) {
        input.layout.autosize = true;
        // NB: This cannot be done in CSS, as Plotly uses this for text size calculations...
        input.layout.font = {
            family: "Helvetica Neue"
        }
    }

    return (
        <div className="fill" style={{ position: "relative" }}>
            {!staticPlot && !disableResizeListener && resizeListener}
            <Plot
                {...rest}
                data={input?.data}
                layout={input?.layout}
                config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: staticPlot || false, displayModeBar: false }}
                style={style}
                onClick={onClick || dataPointClicked}
                onRelayout={persistAxisBoundsToParameters}
            />
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(React.memo(Chart));