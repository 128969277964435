import * as _ from "lodash";
import moment from "moment";
import React from 'react';
import JsxParser from "@recursive-robot/react-jsx-parser";
import { connect } from "react-redux";
import Style from 'style-it';
import * as BaseComponents from "../../shared/components";
import * as Icons from "../../shared/components/icons";
import browserHistory from "../../shared/history";
import { getColumnDateTimeFormatter, getColumnNumberFormatter, formatDate, formatNumber } from "../../shared/utilities";
import { RootState } from "../../store";
import { showDetailedError, showError, showSuccess, showWarning, showInfo } from "../../store/notifications/actions";
import { applyParameterValueChanges, refreshDatasourceByName, updateParameterValue, goToID, goToXYZ } from "../../store/storyline/actions";
import { Template } from "../../store/storyline/types";
import * as StorylineComponents from "../components";
import ScavengerFeedbackColumn from "../components/_bespoke/imperial/ScavengerFeedbackColumn";
import * as TableCellRenderers from "../components/Table/CellRenderers";
import "./Canvas.scss";
import CanvasContentErrorBoundary from "./CanvasContentErrorBoundary";
import { CanvasBindingsProvider } from "../../shared/providers/CanvasBindingsProvider";
import { useSettings } from "../../shared/providers/SettingsProvider";
import * as FileSaver from "file-saver";
import clsx from "clsx";
import * as xlsx from "xlsx";


export const components = { CanvasContentErrorBoundary, ...BaseComponents, Icons, ...StorylineComponents } as Record<string, any>;
export const blacklistedAttrs = [];
export const blacklistedTags = [];
const renderError = ({ error }) => <h2 className="home-content error-message">{`An error occurred while parsing template: ${error}`}</h2>;

// Backwards-compatibility shim for underscore-based array indexing of frame data...
function getUnderscoreBasedIndexingProxy(frameData: any) {
    return new Proxy(frameData, {
        get: function (target, field) {
            if (typeof field === "string") {
                if (field.indexOf("_") === -1) {
                    return target?.[field] ?? window?.[field];
                }

                const path = field.substr(0, field.lastIndexOf("_"));
                const index = field.substr(field.lastIndexOf("_") + 1);

                return target?.[path]?.[index];
            }

            return target?.[field] ?? window?.[field];
        }
    });
}

interface Props {
    template: Template;
    frameData: any;
    parameterValues: Map<string, any>;
    userMetadata: object;
    [key: string]: any;
}

function _Canvas(props: Props) {
    const { template, frameData, navigateTo, updateParameterValueAction, applyParameterValueChangesAction, parameterValues, userMetadata } = props;

    const settings = useSettings();

    // JSX Parser doesn't support lambdas inside bindings, so we need a higher-order function to close over the slide id and expose a suitable parameterless function that can be bound to...
    const navigateToHandler = React.useCallback((slideId) => {
        return () => navigateTo(slideId);
    }, [navigateTo]);

    const updateParameterValueHandler = React.useCallback((name: string) => {
        return (value) => updateParameterValueAction(name, value);
    }, [updateParameterValueAction]);

    const updateParameterValue = React.useCallback((name: string, value: any) => {
        return () => updateParameterValueAction(name, value);
    }, [updateParameterValueAction]);

    const chain = React.useCallback((actions: Function[]) => {
        return () => _.forEach(actions, f => f());
    }, []);

    const applyParameterValueChanges = React.useCallback(applyParameterValueChangesAction, [applyParameterValueChangesAction]);

    const _bindings = {
        ...props, ...frameData, ...Object.fromEntries(parameterValues), navigateToHandler, updateParameterValueHandler, applyParameterValueChanges, ScavengerFeedbackColumn, getColumnNumberFormatter, getColumnDateTimeFormatter, updateParameterValue, chain, browserHistory, userMetadata, moment, ...TableCellRenderers, formatDate, formatNumber, settings, FileSaver, _, Object, clsx, xlsx
    }
    _bindings.__proto__ = getUnderscoreBasedIndexingProxy(frameData);


    const defineFunction = React.useCallback((...args) => {
        try {
            return new Function(...args).bind(_bindings); // eslint-disable-line no-new-func
        }
        catch (e: any) {
            props.showDetailedError("defineFunction: Function body parsing failed.", e.toString());
        }
    }, [_bindings]);

    const _eval = React.useCallback((expr) => eval(expr), []);

    if (!frameData) return <BaseComponents.CircularProgress className="loading-spinner" />;

    const bindings = { ..._bindings, defineFunction, eval: _eval };
    bindings.__proto__ = getUnderscoreBasedIndexingProxy(frameData);

    const result = <div className="jsx-parser">
        <CanvasBindingsProvider bindings={bindings}>
            <JsxParser
                bindings={bindings}
                components={components}
                jsx={`<CanvasContentErrorBoundary>${template?.contents}</CanvasContentErrorBoundary>`}
                showWarnings={true}
                disableKeyGeneration={true}
                blacklistedAttrs={blacklistedAttrs}
                blacklistedTags={blacklistedTags}
                renderError={renderError}
                renderInWrapper={false}
                key={template?.contents} // Force re-parse when template content is updated
            />
        </CanvasBindingsProvider>
    </div>;

    return template?.customCss ?
        Style.it(template.customCss, result) :
        result;
}

const Canvas = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        userMetadata: state.app.userMetadata
    }),
    {
        updateParameterValueAction: updateParameterValue,
        applyParameterValueChangesAction: applyParameterValueChanges,
        refreshDatasourceByName,
        showError,
        showSuccess,
        showDetailedError,
        showWarning,
        showInfo,
        goToID,
        goToXYZ
    })(_Canvas);

export default React.memo(Canvas);