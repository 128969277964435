import React from 'react';
import "./TextField.scss";
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { TextField as BaseTextField } from "../../../shared/components";

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    separator?: string;
    trimWhitespace?: boolean;
}

function TextField(props: Props) {
    const { storyline, updateParameterValue, name, separator, trimWhitespace, ...other } = props;

    const [lastBoundValue, setLastBoundValue] = React.useState(null);

    const getValueFromText = (text: string) => separator ?
        _.chain(_.split(text, separator)).map(s => trimWhitespace ? _.trim(s) : s).filter(a => !!a).value() :
        text;

    const getTextFromValue = (value: any) => separator ?
        _.join(value, trimWhitespace ? separator + " " : separator) :
        value;

    const [value, setValue] = React.useState(() => {
        const newValue = storyline.parameterValues?.get(name);
        setLastBoundValue(newValue);
        return getTextFromValue(newValue);
    });

    const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
        const newText = (event.target as HTMLInputElement).value;
        setValue(newText);

        if (!(event.nativeEvent instanceof UIEvent)) {
            updateParameterValue(name, getValueFromText(newText));
        }
    }

    const handleBlur = (event: any) => {
        const newText = event.target.value;
        updateParameterValue(name, getValueFromText(newText));
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            const newText = getTextFromValue(newValue);

            if (lastBoundValue !== newValue) {
                setValue(newText);
                setLastBoundValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return (
        <BaseTextField
            name={name}
            className="textfield"
            {...other}
            fullWidth
            variant="outlined"
            value={value}
            onInput={handleInput}
            onBlur={handleBlur}
        />
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(TextField);