import * as React from "react";
import { Checkbox, TextField } from "./";
import { Autocomplete as MaterialAutocomplete, AutocompleteProps, AutocompleteRenderInputParams, IconButton } from "@mui/material";
import { X, XCircle, ChevronDown } from "./icons";
import parse from 'autosuggest-highlight/parse';
import prefixMatch from "autosuggest-highlight/match";

function wildcardMatch(text, query) {
    if (!query) return [];

    const results = [];
    const trimmedQuery = query.trim().toLowerCase();
    const textLower = text.toLowerCase();
    const queryLength = trimmedQuery.length;
    let indexOf = textLower.indexOf(trimmedQuery);
    while (indexOf > -1) {
        results.push([indexOf, indexOf + queryLength]);
        indexOf = textLower.indexOf(query, indexOf + queryLength);
    }
    return results;
}

interface MyAutocompleteProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
    > extends Omit<AutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
    >, 'renderInput'> {
    // We're providing a default implementation for "renderInput" below, so omit the original (required) version and override it with an optional equivalent...
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
    label?: string;
    highlightMatchPattern?: "prefix" | "wildcard";
    placeholder?: string;
    helperText?: string;
    error?: boolean;
}

interface Option {
    value: any;
    label: string;
}

function Autocomplete<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    >(props: MyAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>): JSX.Element {
    const { multiple, label, highlightMatchPattern, placeholder, error, helperText } = props;
    const matchHandler = highlightMatchPattern === "prefix" ? prefixMatch : wildcardMatch;

    return <MaterialAutocomplete
        ChipProps={{ deleteIcon: <IconButton><X size="small" /></IconButton>, ...props?.ChipProps }}
        clearIcon={<XCircle size="small" />}
        popupIcon={<ChevronDown size="small" />}
        blurOnSelect={!multiple}
        disableCloseOnSelect={multiple}
        openOnFocus
        autoHighlight
        renderOption={(props, option, { inputValue, selected }) => {
            const matches = matchHandler(option.label, inputValue);
            const parts = parse(option.label, matches);

            return (
                <li {...props}>
                    {
                        multiple ?
                            <Checkbox
                                color="primary"
                                style={{ marginRight: 8 }}
                                checked={selected}
                            /> :
                            null
                    }
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} className={part.highlight ? "highlight-text" : ""}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                </li>
            );
        }}
        renderInput={(params) => <TextField label={label} placeholder={placeholder} error={error} helperText={helperText} {...params} />}
        isOptionEqualToValue={(option: any, value: any) => option?.value == value?.value}
        getOptionLabel={(option: any) => option?.label || ""}
        {...props}
    />
};

export { Autocomplete };
export type { Option };
