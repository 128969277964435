import * as React from "react";
import { FC, useCallback, useRef } from "react";
import NewWindow, { INewWindowProps } from "react-new-window";

/**
 * Wrapper for react-new-window that works with MUI (and Emotion).
 *
 * Same interface as react-new-window.
 */
const PopoutWindow: FC<INewWindowProps> = ({
    onOpen,
    onUnload,
    ...props
}) => {
    const mutationObserverRef = useRef<MutationObserver | null>(null);

    const compatOnOpen = useCallback(
        (childWindow: Window) => {
            const childHead = childWindow.document.head;
            const mutationObserver = new MutationObserver((mutationList) => {
                mutationList
                    .flatMap((mutation) => Array.from(mutation.addedNodes))
                    .filter((node) => node instanceof HTMLStyleElement)
                    .forEach((styleEl) => {
                        childHead.appendChild(styleEl.cloneNode(true));
                    });
            });
            mutationObserver.observe(document.head, { childList: true });
            mutationObserverRef.current = mutationObserver;
            onOpen?.(childWindow);
        },
        [onOpen]
    );

    const compatOnUnload = useCallback(() => {
        mutationObserverRef.current?.disconnect();
        onUnload?.();
    }, [onUnload]);

    return (
        <NewWindow onOpen={compatOnOpen} onUnload={compatOnUnload} {...props} />
    );
};

export default PopoutWindow;