import * as React from "react";
import "./TreeView.scss";
import { TreeView as BaseTreeView } from "../../../shared/components";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";

function getFullHierarchy(node: TreeViewNode): TreeViewNode[] {
    const allChildren = _.flatMap(node.children, getFullHierarchy);
    return [node, ...allChildren];
}

function containsSelectedNodes(selectedNodeIds: string[], currentNode: TreeViewNode): boolean {
    if (selectedNodeIds.find(nId => currentNode.id === nId)) {
        return true;
    }
    if (!currentNode.children) {
        return false;
    }

    return !!currentNode.children.find(node => containsSelectedNodes(selectedNodeIds, node));
}

export interface TreeViewNode {
    id?: string;
    title: string;
    children?: TreeViewNode[]
    isExpanded?: boolean;
    isSelected?: boolean;
}

interface TreeViewProps {
    nodes: TreeViewNode[];
    multiSelect?: boolean;
    onNodeSelect: (selectedNodes: TreeViewNode[]) => void;
    name?: string;
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
    showSearch?: boolean;
}

function _TreeView(props: TreeViewProps) {
    const { nodes, onNodeSelect, multiSelect, name, parameterValues, updateParameterValue, showSearch, ...rest } = props;
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [highlighted, setHighlighted] = React.useState<string[]>([]);
    const [currentNodes, setCurrentNodes] = React.useState([]);

    React.useEffect(() => {
        if (currentNodes?.length === nodes?.length && _.isMatch(currentNodes, nodes)) return;
        setCurrentNodes(nodes);

        const defaultExpandedNodeIds = Array.isArray(nodes) ? _.flatMap(nodes, getFullHierarchy).filter(n => n.isExpanded).map(n => n.id) : [];
        setExpanded(defaultExpandedNodeIds);

        const defaultSelectedNodeIds = Array.isArray(nodes) ? _.flatMap(nodes, getFullHierarchy).filter(n => n.isSelected).map(n => n.id) : [];
        setSelected(defaultSelectedNodeIds);

        const allNodes = _.flatMap(nodes, getFullHierarchy);
        const highlightedNodes = allNodes.filter(n => containsSelectedNodes(defaultSelectedNodeIds, n)).map(n => n.id);
        setHighlighted(highlightedNodes);
    }, [nodes]);

    React.useEffect(() => {
        if (name && parameterValues.has(name)) {
            const parameterValue = parameterValues.get(name);
            const newSelected: string[] = _.isArray(parameterValue) ? parameterValue : [parameterValue];
            if (selected?.length !== newSelected?.length || !_.isMatch(selected, newSelected)) {
                setSelected(newSelected);

                // Expand all the ancestors of the selected nodes...
                const allNodes = _.flatMap(nodes, getFullHierarchy);
                const expandedNodes = allNodes.filter(n => containsSelectedNodes(newSelected, n)).map(n => n.id);
                setExpanded([...expanded, ...expandedNodes]);
                setHighlighted([...expandedNodes]);
            }
        }
    }, [parameterValues, nodes]);

    const handleSelect = React.useCallback((selectedNodes: TreeViewNode[]) => {
        if (onNodeSelect) {
            onNodeSelect(selectedNodes);
        }

        if (name) {
            updateParameterValue(name, multiSelect ? selectedNodes.map(n => n.id) : selectedNodes?.[0]?.id);
        }
    }, [onNodeSelect, name, multiSelect]);

    return (
        <BaseTreeView
            {...rest}
            defaultExpanded={expanded}
            defaultHighlighted={highlighted}
            defaultSelected={selected}
            nodes={currentNodes}
            multiSelect={multiSelect}
            onSelectionChanged={handleSelect}
            showSearch={showSearch}
        />
    );
}

const TreeView = connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(_TreeView);

export { TreeView };