import React from "react";
import "./App.scss";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";
import history from "../shared/history";
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { loadApp } from "../store/app/actions";
import { AjaxLoadingIndicator } from "../shared/components/AjaxLoadingIndicator";
import { incrementOutstandingRequestCount, decrementOutstandingRequestCount } from "../store/notifications/actions";
import { RootState } from "../store";
import { AppState } from "../store/app/types";
import { menuItems as adminMenuItems, default as Admin } from "../admin";
import Viewer from "../viewer";
import Public from "../public";
import LoginCallback from "../auth/LoginCallback";
import fetchIntercept from 'fetch-intercept';
import { useAuth0 } from "../auth/AuthContext";
import { SnackbarProvider } from 'notistack';
import Notifier from "../shared/components/Notifier";
import RequireAuthentication from "../auth/RequireAuthentication";
import Sidenav from "../shared/components/Sidenav";
import UserPreferences from "../shared/pages/UserPreferences";
import NotFound from "../shared/pages/NotFound";
import * as colors from "../shared/colors";

const theme = createTheme({
    palette: {
        background: {
            default: colors.grey_7
        },
        primary: {
            main: colors.blue_1,
            contrastText: colors.white
        },
        secondary: {
            main: colors.blue_3,
            contrastText: colors.white
        },
        info: {
            main: colors.dark_blue_3,
            contrastText: colors.white
        },
        success: {
            main: colors.green_1,
            contrastText: colors.white
        },
        warning: {
            main: colors.orange_1,
            contrastText: colors.white
        },
        error: {
            main: colors.red_1,
            contrastText: colors.white
        },
        tonalOffset: 0.1
    },
    typography: {
        fontFamily: [
            "Helvetica Neue"
        ].join(",")
    }
});

LicenseInfo.setLicenseKey(
    "c0999568c3def75091e9f9d50d778258T1JERVI6MjczNTksRVhQSVJZPTE2NTg0NzIyOTgwMDAsS0VZVkVSU0lPTj0x"
);

interface AppProps {
    app: AppState,
    loadApp: typeof loadApp,
    incrementOutstandingRequestCount: typeof incrementOutstandingRequestCount,
    decrementOutstandingRequestCount: typeof decrementOutstandingRequestCount
}

function App(props: AppProps) {
    const { app, loadApp, incrementOutstandingRequestCount, decrementOutstandingRequestCount } = props;
    const { isAuthenticated, getTokenSilently } = useAuth0();
    const [isInitialized, setIsInitialized] = React.useState(false);

    React.useEffect(() => {
        if (!isAuthenticated) return;

        // Register an HTTP interceptor that adds the latest JWT token to the request headers and tracks outstanding AJAX requests...
        const unregister = fetchIntercept.register({
            request: async (url, config) => {
                if (url?.startsWith?.(window.location.origin) || url?.startsWith?.("/")) {
                    incrementOutstandingRequestCount();

                    if (isAuthenticated && config != null) {
                        const token = await getTokenSilently();
                        config.headers["Authorization"] = `Bearer ${token}`;
                    }
                }

                return [url, config];
            },
            requestError: function (error) {
                decrementOutstandingRequestCount();

                // Called when an error occured during another 'request' interceptor call
                return Promise.reject(error);
            },
            response: function (response) {
                if (response?.url?.startsWith?.(window.location.origin) || response?.url?.startsWith?.("/")) {
                    decrementOutstandingRequestCount();
                }
                // Modify the reponse object
                return response;
            },
            responseError: function (error) {
                decrementOutstandingRequestCount();

                // Handles fetch error...
                return Promise.reject(error);
            }
        });

        loadApp(); // Load the storylines and canvases for the current tenant once-off...

        setIsInitialized(true);

        return unregister;
    }, [isAuthenticated]);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                maxSnack={5}
            >
                <Notifier />
                <AjaxLoadingIndicator />
                <HistoryRouter history={history}>
                    <Routes>
                        <Route path="not-found" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <Sidenav menuItems={app.menuItems} />
                                <div className="content">
                                    <NotFound />
                                </div>
                            </RequireAuthentication>
                        } />
                        <Route path="login-callback" element={<LoginCallback />} />
                        <Route path="preferences" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <Sidenav menuItems={app.menuItems} />
                                <div className="content">
                                    <UserPreferences />
                                </div>
                            </RequireAuthentication>
                        } />
                        <Route path="public/*" element={<Public />} />
                        <Route path="admin/*" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <Sidenav menuItems={adminMenuItems} />
                                <div className="content">
                                    <Admin />
                                </div>
                            </RequireAuthentication>
                        } />
                        <Route path="/*" element={
                            <RequireAuthentication isInitialized={isInitialized}>
                                <Viewer />
                            </RequireAuthentication>
                        } />
                    </Routes>
                </HistoryRouter>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default connect(
    (state: RootState) => ({
        app: state.app
    }),
    { incrementOutstandingRequestCount, decrementOutstandingRequestCount, loadApp: loadApp as any })(App);