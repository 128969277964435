import React from "react";
import {
    ENQUEUE_SNACKBAR,
    CLOSE_SNACKBAR,
    REMOVE_SNACKBAR,
    INCREMENT_OUTSTANDING_REQUEST_COUNT,
    DECREMENT_OUTSTANDING_REQUEST_COUNT
} from "./types";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, ErrorAlert, SuccessAlert, DetailedErrorAlert, WarningAlert, InfoAlert } from "../../shared/components";

export function enqueueSnackbar(notification) {
    return (dispatch) => {
        const key = notification?.options?.key || new Date().getTime() + Math.random();

        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                ...notification,
                key,
                options: {
                    action: key => (
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(closeSnackbar(key))}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    ),
                    ...notification.options
                }
            },
        });
    }
}

export function closeSnackbar(key) {
    return {
        type: CLOSE_SNACKBAR,
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    }
}

export function removeSnackbar(key) {
    return {
        type: REMOVE_SNACKBAR,
        key,
    }
}

export function showError(errorMessage) {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: errorMessage,
                key: new Date().getTime() + Math.random(),
                options: {
                    content: (key, message) => <ErrorAlert id={key} children={message} onClose={() => dispatch(closeSnackbar(key))} />,
                    variant: 'error',
                    persist: true
                }
            },
        })
    }
}

export function showDetailedError(errorMessage, details = "") {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: errorMessage,
                details: details,
                key: new Date().getTime() + Math.random(),
                options: {
                    content: (key, message) => <DetailedErrorAlert id={key} message={message} details={details} />,
                    variant: 'error',
                    persist: true
                }
            },
        })
    }
}

export function showSuccess(successMessage) {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: successMessage,
                key: new Date().getTime() + Math.random(),
                options: {
                    content: (key, message) => <SuccessAlert id={key} children={message} onClose={() => dispatch(closeSnackbar(key))} />,
                    variant: "success"
                }
            }
        });
    }
}

export function showWarning(message) {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: message,
                key: new Date().getTime() + Math.random(),
                options: {
                    content: (key, message) => <WarningAlert id={key} children={message} onClose={() => dispatch(closeSnackbar(key))} />,
                    variant: "warning"
                }
            }
        });
    }
}

export function showInfo(message) {
    return (dispatch) => {
        dispatch({
            type: ENQUEUE_SNACKBAR,
            notification: {
                message: message,
                key: new Date().getTime() + Math.random(),
                options: {
                    content: (key, message) => <InfoAlert id={key} children={message} onClose={() => dispatch(closeSnackbar(key))} />,
                    variant: "info"
                }
            }
        });
    }
}

export function incrementOutstandingRequestCount() {
    return {
        type: INCREMENT_OUTSTANDING_REQUEST_COUNT
    }
}

export function decrementOutstandingRequestCount() {
    return {
        type: DECREMENT_OUTSTANDING_REQUEST_COUNT
    }
}