import * as React from "react";
import { Checkbox as MaterialCheckbox, CheckboxProps } from "@mui/material";
import { Tasklist, Box, CheckboxMinus } from "./icons";

const Checkbox: React.FunctionComponent<CheckboxProps> = React.forwardRef((props: CheckboxProps, ref) => <MaterialCheckbox
    ref={ref}
    checkedIcon={<span className="check-container"><Tasklist size="medium" /></span>}
    indeterminateIcon={<span className="check-container"><CheckboxMinus size="medium" /></span>}
    icon={<span className="check-container"><Box size="medium" /></span>}
    {...props} />
);

export default Checkbox;