import React, { useState } from 'react';
import { Collapse, Paper, IconButton } from "@mui/material";
import { Close, ExpandMore, ExpandLess } from "@mui/icons-material";
import { CircleSlash } from "./icons";
import { useSnackbar } from 'notistack';

const DetailedErrorAlert = React.forwardRef<any, any>((props: any, ref) => {
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(props.id);
    };

    return (
        <div className="col-fill">
            <Paper ref={ref} className="MuiAlert-root MuiAlert-standard MuiAlert-standardError" elevation={0} role="alert">
                <div className="MuiAlert-icon">
                    <CircleSlash />
                </div>
                <div className="MuiAlert-message">
                    {props.message}
                </div>
                <div className="MuiAlert-action">
                    <IconButton
                        size="small"
                        color="inherit"
                        aria-label="Show more"
                        onClick={handleExpandClick}
                    >
                        {
                            expanded ?
                            <ExpandLess fontSize="small" /> :
                            <ExpandMore fontSize="small" />
                        }
                    </IconButton>
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={handleDismiss}>
                        <Close fontSize="small" />
                    </IconButton>
                </div>
            </Paper>
            <Collapse in={expanded} timeout="auto" unmountOnExit className="detailed-error-text">
                <pre>{props.details || props.message}</pre>
            </Collapse>
        </div>
    );
});

export default DetailedErrorAlert;