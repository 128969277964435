import * as React from "react";
import { Button } from "../../Button";
import { object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { Autocomplete, Option, Dialog, DialogActions, DialogContent, DialogTitleWithCloseButton, ConfirmationDialog } from "../../../../shared/components";

interface ClearDialogProps {
    entityName: string;
    entities: Option[];
    selectedValue?: Option;
    acceptCallback: (args: Object) => void;
    closeCallback: () => void;
}

function ClearDialog(props: ClearDialogProps) {
    const { entityName, entities, selectedValue, acceptCallback, closeCallback } = props;
    const schema = object().shape({
        entity: object()
            .nullable()
            .required(`Please select a ${entityName} to clear.`)
    });
    const { handleSubmit, errors, control } = useForm({ resolver: yupResolver(schema), defaultValues: { entity: (selectedValue as any), name: "" } });

    const [onClearCallback, setOnClearCallback] = React.useState(null);
    const [confirmationLabel, setConfirmationLabel] = React.useState("");
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const save = React.useCallback((formModel: { entity: Option }) => {
        const onClearCallback = () => acceptCallback({
            entity_id: formModel.entity.value,
            entity_label: formModel.entity.label
        });
        setOnClearCallback(() => onClearCallback);
        setConfirmationLabel(formModel.entity.label);
        setShowConfirmation(true);
    }, [entities, acceptCallback]);

    return (
        <>
            <Dialog
                open={true}
                onClose={(_event, reason) => {
                    if (reason !== 'backdropClick') {
                        closeCallback();
                    }
                }}
                aria-labelledby="form-dialog-title">
                <DialogTitleWithCloseButton onClose={closeCallback} id="form-dialog-title">
                    Clear {entityName}
                </DialogTitleWithCloseButton>
                <form onSubmit={handleSubmit(save)} autoComplete="off">
                    <DialogContent>
                        <div className="vertical-form-field">
                            Select {entityName} to clear:
                        </div>
                        <Controller
                            control={control}
                            name="entity"
                            render={({
                                onChange,
                                value
                            }) => (
                                <Autocomplete
                                    onChange={(_event, option, _reason) => onChange((option as Option))}
                                    isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
                                    getOptionLabel={(option: any) => option?.label || ""}
                                    value={value}
                                    size="small"
                                    fullWidth
                                    options={entities}
                                    error={!!errors.entity}
                                    helperText={errors.entity?.message}
                                />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="text" color="warning">
                            Clear
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {
                showConfirmation && <ConfirmationDialog
                    title={`Clear ${entityName}?`}
                    open={showConfirmation}
                    setOpen={setShowConfirmation}
                    onConfirm={onClearCallback}
                >
                    <h3>Warning:</h3>
                    <br />
                    This operation will clear all values in '{confirmationLabel}'. This cannot be reversed!
                    <br />
                    <br />
                </ConfirmationDialog>
            }
        </>
    );
}

export { ClearDialog };