import * as React from "react";
import clsx from "clsx";
import { default as Markdown } from "../Markdown";

interface ContentRendererProps {
    className?: string;
    input?: string;
    bindings?: Object;
}

const TextRenderer = (text, _className = null, _bindings = null) => text;
const HtmlRenderer = (text, className = null, _bindings = null) => <div className={clsx(className)} dangerouslySetInnerHTML={{ __html: text }} />;
const JsxRenderer = (text, _className = null, bindings = null) => {
    const { TemplateRenderer } = require("../TemplateRenderer");
    return <TemplateRenderer template={text} data={bindings} />;
};
const MarkdownRenderer = (text, _className, _bindings) => <Markdown source={text} />;

const prefixMap = {
    "html:": HtmlRenderer,
    "jsx:": JsxRenderer,
    "markdown:": MarkdownRenderer,
    "": TextRenderer
};

function _ContentRenderer(props: ContentRendererProps) {
    const { className, input, bindings } = props;

    for (let prefix of Object.keys(prefixMap)) {
        if (input?.startsWith(prefix)) {
            return prefixMap[prefix](input.substring(prefix.length), className, bindings);
        }
    }
}

const ContentRenderer = React.memo(_ContentRenderer);

export { ContentRenderer };