import * as React from "react";
import clsx from "clsx";
import "./filters.scss";

interface FilterRowProps {
    className?: any;
    children?: any;
}

function FilterRow(props: FilterRowProps) {
    const { className, children } = props;

    return (
        <div className={clsx("canvas-filter-row", className)}>
            {children}
        </div>
    );
}

interface FilterProps {
    title?: string;
    children?: any;
}

function Filter(props: FilterProps) {
    const { title, children } = props;

    return (
        <div className="canvas-filter">
            <span className="filter-title">{title}</span>
            <div className="controls-list">
                {children}
            </div>
        </div>
    );
}

export { FilterRow, Filter };