import "./CrudPanel.scss";
import * as React from "react";
import { updateParameterValue } from "../../../store/storyline/actions";
import { default as Autocomplete, Option } from "../Autocomplete";
import { Button } from "../Button";
import { Pencil } from "../../../shared/components/icons";
import { connect } from "react-redux";
import { CreateDialog } from "./dialogs/CreateDialog";
import { CloneDialog } from "./dialogs/CloneDialog";
import { RenameDialog } from "./dialogs/RenameDialog";
import { ClearDialog } from "./dialogs/ClearDialog";
import { RemoveDialog } from "./dialogs/RemoveDialog";

type CrudOperation = "create" | "clone" | "rename" | "clear" | "remove";

interface CrudPanelProps {
    entityName: string;
    options: Option[];
    selectedItemParameter: string;
    onCrudOperation?: (operation: string, args?: Object) => void;
    onSelectionChanged?: (newValue: Option) => void;
    availableOperations?: CrudOperation[];
}

function CrudPanel(props: CrudPanelProps) {
    const { entityName, options, selectedItemParameter, onCrudOperation, onSelectionChanged } = props;
    const availableOperations = props.availableOperations ?? ["create", "clone", "rename", "clear", "remove"];
    const [selectedValue, setSelectedValue] = React.useState(null);

    const [isExpanded, setIsExpanded] = React.useState(false);
    const toggleIsExpanded = React.useCallback(() => {
        setIsExpanded(value => !value);
    }, [setIsExpanded]);

    const [currentOperation, setCurrentOperation] = React.useState("");
    const acceptModalCallback = React.useCallback((args: Object) => {
        onCrudOperation && onCrudOperation(currentOperation, args);
        setCurrentOperation("");
    }, [currentOperation, setCurrentOperation, onCrudOperation]);
    const closeModalCallback = React.useCallback(() => {
        setCurrentOperation("");
    }, [setCurrentOperation]);

    const handleSelection = (_event: React.ChangeEvent<HTMLDivElement>, option: Option | Option[]) => {
        setSelectedValue((option as Option));
        onSelectionChanged && onSelectionChanged(option as Option);
    }

    return (
        <div className="crud-panel">
            <Autocomplete name={selectedItemParameter} options={options} onChange={handleSelection} />
            <Button variant="outlined" color="primary" size="small" onClick={toggleIsExpanded}>
                <Pencil size="small" />
            </Button>
            {
                isExpanded &&
                <div className="crud-buttons">
                    {availableOperations.find(o => o === "create") && <Button variant="outlined" color="success" size="small" onClick={() => setCurrentOperation("create")}>New</Button>}
                    {availableOperations.find(o => o === "clone") && <Button variant="outlined" color="info" size="small" onClick={() => setCurrentOperation("clone")}>Clone</Button>}
                    {availableOperations.find(o => o === "rename") && <Button variant="outlined" color="secondary" size="small" onClick={() => setCurrentOperation("rename")}>Rename</Button>}
                    {availableOperations.find(o => o === "clear") && <Button variant="outlined" color="warning" size="small" onClick={() => setCurrentOperation("clear")}>Clear</Button>}
                    {availableOperations.find(o => o === "remove") && <Button variant="outlined" color="error" size="small" onClick={() => setCurrentOperation("remove")}>Remove</Button>}
                </div>
            }

            {currentOperation === "create" && <CreateDialog entityName={entityName} entities={options} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "clone" && <CloneDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "rename" && <RenameDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "clear" && <ClearDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}
            {currentOperation === "remove" && <RemoveDialog entityName={entityName} entities={options} selectedValue={selectedValue} acceptCallback={acceptModalCallback} closeCallback={closeModalCallback} />}

        </div>
    );
}

export default connect(
    null,
    { updateParameterValue: updateParameterValue })(CrudPanel);