import moment from "moment";


export function convertStringToValue(valueString: string) {
    // Check if value is a number...
    const number = Number(valueString);
    if (!isNaN(number)) return number;

    // Check if value is a date...
    const date = new Date(valueString);
    if (date instanceof Date && !isNaN(date as any)) return date;

    // No matches - assume the value is a plain string...
    return valueString;
}

function convertAbbreviatedNumberToText(_, negative: string, currencyCode: string, number: number, uom: "k" | "m" | "b") {
    const uoms = {
        "k": " thousand",
        "m": " million",
        "b": " billion"
    };

    const currencies = {
        "R": " rand",
        "$": " dollar",
        "%": " percent"
    };

    return `${negative ? "minus " : ""}${number}${uoms[uom] || ""}${currencies[currencyCode] || ""}`;
}

export function sanitizeTextForNarration(text: string | string[]) {
    let result = (text instanceof Array) ? text.join(". ") : text; // Concatenate sentences together or use the single sentence...
    result = result.replace(/<[^>]+>/g, ''); // Remove all HTML markup...
    result = result.replace(/(-)*\s*(R|$)*\s*([0-9.0-9]+\s*)(k|m|b)?/g, convertAbbreviatedNumberToText); // Convert abbreviated numbers/percentages to their reader-friendly counterparts...
    return result;
}

/// Higher-order wrapper around Intl.NumberFormat object that allows us to use it within template bindings...
export function getColumnNumberFormatter(decimalPlaces = 2, prefix = "", suffix = "", multiplicationFactor = 1) {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    } as Intl.NumberFormatOptions);

    return (params: { value: number }) => {
        if (params?.value === null || params?.value === undefined) return "";

        return `${prefix}${formatter.format((params?.value || 0) * multiplicationFactor)}${suffix}`;
    }
}

export function formatNumber(value: number, decimalPlaces = 2, prefix = "", suffix = "", multiplicationFactor = 1) {
    return getColumnNumberFormatter(decimalPlaces, prefix, suffix, multiplicationFactor)({ value });
}

/// Higher-order wrapper around moment.format that allows us to use it within template bindings...
export function getColumnDateTimeFormatter(formatString = "YYYY-MM-DD HH:mm") {
    return (params: { value: string | Date | moment.Moment }) => moment(params?.value).format(formatString);
}

export function formatDate(value: string | Date | moment.Moment, formatString = "YYYY-MM-DD HH:mm") {
    return getColumnDateTimeFormatter(formatString)({ value });
}

let measuringCanvas: HTMLCanvasElement = null;

/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
export function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = measuringCanvas || (measuringCanvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

export function describeArc(x, y, radius, startAngle, endAngle) {
    endAngle = Math.min(endAngle, 359.9999);
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
        "L", x, y,
        "L", start.x, start.y
    ].join(" ");

    return d;
}
