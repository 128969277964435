import React, { useEffect, useRef } from "react";
import { Polygon } from "@react-google-maps/api";
import { GeoPolygonModel } from "./services/types";
import { GeoEditPolygonDialog } from "./edit";
import { useDialogStore } from "./shared/dialogs/store";
import { polygonOptions } from "./services/constants";

export interface GeoPolygonProps {
  model: GeoPolygonModel;
  onUpdated: (model: GeoPolygonModel) => void;
  onDeleted: (id: string) => void;
}

export const GeoPolygon: React.FunctionComponent<GeoPolygonProps> = (props) => {
  const showDialog = useDialogStore((state) => state.show);
  const hideDialog = useDialogStore((state) => state.hide);
  const polygonRef = useRef<google.maps.Polygon | null>();

  useEffect(() => {
    const polygon = polygonRef.current;
    if (!polygon) return;

    const path = polygon.getPath();
    google.maps.event.addListener(path, "set_at", handlePathChange);
    google.maps.event.addListener(path, "insert_at", handlePathChange);
    google.maps.event.addListener(path, "remove_at", handlePathChange);
  }, [polygonRef.current, handlePathChange]);

  function handlePathChange() {
    if (!polygonRef.current) return;

    const nextPath = polygonRef.current
      .getPath()
      .getArray()
      .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

    const updatedModel = { ...props.model, path: nextPath };
    props.onUpdated(updatedModel);
  }

  function handleClick(e: google.maps.MapMouseEvent) {
    showDialog(
      "Edit",
      <GeoEditPolygonDialog
        name={props.model.name}
        site={props.model.site}
        poiType={props.model.poiType}
        tat={props.model.tat}
        lane={props.model.lane}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    );
  }

  function handleSave(
    name: string,
    site: string,
    poiType: string,
    tat: string,
    lane: string
  ) {
    const updatedModel = { ...props.model, name, site, poiType, tat, lane };
    props.onUpdated(updatedModel);

    hideDialog();
  }

  function handleDelete() {
    props.onDeleted(props.model.id);

    hideDialog();
  }

  return (
    <Polygon
      ref={(ctrl) => (polygonRef.current = ctrl?.state.polygon)}
      editable
      path={props.model.path}
      options={polygonOptions}
      onClick={handleClick}
    />
  );
};
