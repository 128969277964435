interface ErrorProps {
    error?: any;
    errorInfo?: any;
}

export default function Error(props: ErrorProps) {
    const { error, errorInfo } = props;

    return (
        <div className="content">
            <div className="home-content">
                <h2 id="home-header-2">Something went wrong.</h2>
                <br />
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    <pre style={{textAlign: "left"}}>
                        {error && error.toString()}
                        <br />
                        {errorInfo && errorInfo.componentStack}
                    </pre>
                </details>
            </div>
        </div>
    );
}