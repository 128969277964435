import * as React from "react";
import "./TreeView.scss";
import { TreeView as MaterialTreeView, TreeItem as MaterialTreeItem, TreeViewProps as MaterialTreeViewProps, MultiSelectTreeViewProps } from "@mui/lab";
import { OutlinedInput, InputAdornment } from "./";
import { TriangleRight, TriangleDown, Search } from "./icons";
import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { tap, debounceTime } from 'rxjs/operators';
import clsx from "clsx";

function filterItems(node: TreeViewNode, searchTerm: string) {
    // Search term is blank or this node matches the search criteria...
    if (!searchTerm || node.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return node;
    }

    const survivors = _.chain(node.children).map(a => filterItems(a, searchTerm)).filter(a => !!a).value();

    if (survivors?.length > 0) {
        return {
            ...node,
            children: survivors
        };
    } else {
        return null;
    }
}

function highlightSearchTerm(text: string, searchTerm: string) {
    const startIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (startIndex === -1) return <span>{text}</span>;

    const endIndex = startIndex + searchTerm.length;
    return <span>
        {text.slice(0, startIndex)}
        <mark>{text.slice(startIndex, endIndex)}</mark>
        {text.slice(endIndex, text.length)}
    </span>;
}

function getFullHierarchy(node: TreeViewNode): TreeViewNode[] {
    const allChildren = _.flatMap(node.children, getFullHierarchy);
    return [node, ...allChildren];
}

function containsSelectedNodes(selectedNodeIds: string[], currentNode: TreeViewNode): boolean {
    if (selectedNodeIds.find(nId => currentNode.id === nId)) {
        return true;
    }
    if (!currentNode.children) {
        return false;
    }

    return !!currentNode.children.find(node => containsSelectedNodes(selectedNodeIds, node));
}

// It gets complicated when you try to trim the branches midway, so instead we just cap the number of leaf nodes in the tree when rendering the search results.
function takeNLeafNodesFromTree(tree: TreeViewNode[], n: number) {
    let remainder = n;

    const getPrunedMenuItem = (item: TreeViewNode) => {
        if (!item.children?.length) {
            if (remainder > 0) {
                remainder--;
                return item;
            }
            else {
                return null;
            }
        }
        else {
            let trimmedChildren = item.children?.map(getPrunedMenuItem).filter(a => !!a);
            if (!trimmedChildren.length) {
                return null;
            }
            else {
                return { ...item, children: trimmedChildren };
            }
        }
    }

    return tree.map(getPrunedMenuItem).filter(a => !!a);
}

function hasAncestorWithClassName(element: HTMLElement, className: string) {
    if (!element) return false;
    if (element.classList.contains(className)) return true;

    return hasAncestorWithClassName(element.parentElement, className);
}

export interface TreeViewNode {
    id?: string;
    title: string;
    children?: TreeViewNode[];
}

interface TreeViewProps extends Omit<MaterialTreeViewProps, 'multiSelect'> {
    nodes: TreeViewNode[];
    multiSelect?: boolean;
    onSelectionChanged?: (selectedNodes: TreeViewNode[]) => void;
    showSearch?: boolean;
    defaultSearchTerm?: string;
    defaultExpanded?: string[];
    defaultSelected?: string[];
    defaultHighlighted?: string[];
    multiSelectWithoutCtrl?: boolean;
}

function TreeView(props: TreeViewProps) {
    const { nodes, onSelectionChanged, multiSelect, showSearch, defaultSearchTerm, defaultExpanded, defaultSelected, defaultHighlighted, multiSelectWithoutCtrl } = props;
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [highlighted, setHighlighted] = React.useState<string[]>([]);

    const [searchTermSubject$, _setSearchTermSubject$] = React.useState(new BehaviorSubject<string>(""));
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchTermText, setSearchTermText] = React.useState("");
    const [filteredItems, setFilteredItems] = React.useState(nodes ?? []);
    const [expandedSearchNodes, setExpandedSearchNodes] = React.useState([]);

    const renderNode = React.useCallback((item: TreeViewNode) => {
        return (
            <MaterialTreeItem key={item.id} nodeId={item.id} label={searchTerm ? highlightSearchTerm(item.title, searchTerm) : item.title} className={clsx(highlighted.find(h => h === item.id) && "active")}>
                {
                    Array.isArray(item.children) ?
                        item.children.map((node) => renderNode(node)) :
                        null
                }
            </MaterialTreeItem>
        )
    }, [onSelectionChanged, highlighted, searchTerm]);

    React.useEffect(() => {
        const searchTermSubscription = searchTermSubject$
            .pipe(
                tap(setSearchTermText), // Immediately update the text in the input control...
                debounceTime(500)       // And debounce the update of the search term...
            ).subscribe(setSearchTerm);

        return () => searchTermSubscription.unsubscribe();
    }, []);

    React.useEffect(() => {
        if (_.isEqual(searchTerm, defaultSearchTerm)) return;

        searchTermSubject$.next(defaultSearchTerm);
    }, [defaultSearchTerm]);

    React.useEffect(() => {
        if (_.isEqual(expanded, (defaultExpanded ?? []))) return;

        setExpanded(defaultExpanded);
    }, [defaultExpanded]);

    React.useEffect(() => {
        if (_.isEqual(highlighted, (defaultHighlighted ?? []))) return;

        setHighlighted(defaultHighlighted);
    }, [defaultHighlighted]);

    React.useEffect(() => {
        if (_.isEqual(selected, (defaultSelected ?? []))) return;

        setSelected(defaultSelected);

        // Highlight and expand parent nodes of selected nodes...
        const allNodes = _.flatMap(nodes, getFullHierarchy);
        const normalizedNodeIds = Array.isArray(defaultSelected) ? defaultSelected : [defaultSelected];
        const selectedNodes = normalizedNodeIds.map(nodeId => allNodes.find(n => nodeId === n.id));
        const highlightedNodes = allNodes.filter(n => containsSelectedNodes(selectedNodes.map(sn => sn.id), n)).map(n => n.id);
        setHighlighted(highlightedNodes);
        setExpanded(_.uniq([...expanded, ...(highlightedNodes.filter(n => !normalizedNodeIds.find(ni => ni === n)))]));

    }, [defaultSelected]);


    React.useEffect(() => {
        if (searchTerm) {
            let filteredResults = _.chain(nodes).map(a => filterItems(a, searchTerm)).filter(a => !!a).value();
            filteredResults = takeNLeafNodesFromTree(filteredResults, 200); // Cap the search results at 200 leaf nodes, for performance reasons...
            setFilteredItems(filteredResults);
            setExpandedSearchNodes(_.chain(filteredResults).flatMapDeep(getFullHierarchy).filter(mi => mi?.children?.length > 0).map(mi => mi.id).value());
        }
        else {
            setFilteredItems(nodes);
            setExpandedSearchNodes([]);
        }
    }, [nodes, searchTerm]);

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        // Don't treat label clicks as collapse/expand events...
        if (!hasAncestorWithClassName(event?.target as HTMLElement, "MuiTreeItem-iconContainer")) return;

        setExpanded(nodeIds);
    };

    const handleSelect = React.useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
        // Don't treat collapse/expand button clicks as selection...
        if (hasAncestorWithClassName(event?.target as HTMLElement, "MuiTreeItem-iconContainer")) return;

        // Workaround for scenarios where we don't want the user to explicitly use Ctrl when multi-selecting...
        if (multiSelect && multiSelectWithoutCtrl && nodeIds.length <= 1) {
            const toggledNodeId = nodeIds?.[0];
            if (selected.find(s => s === toggledNodeId)) {
                nodeIds = selected.filter(s => s !== toggledNodeId);
            } else {
                nodeIds = [...selected, toggledNodeId];
            }
        }

        setSelected(nodeIds);

        const allNodes = _.flatMap(nodes, getFullHierarchy);
        const normalizedNodeIds = Array.isArray(nodeIds) ? nodeIds : [nodeIds];
        const selectedNodes = normalizedNodeIds.map(nodeId => allNodes.find(n => nodeId === n.id));
        const highlightedNodes = allNodes.filter(n => containsSelectedNodes(selectedNodes.map(sn => sn.id), n)).map(n => n.id);
        setHighlighted(highlightedNodes);

        if (onSelectionChanged) {
            onSelectionChanged(selectedNodes);
        }

    }, [onSelectionChanged, nodes, selected]);

    return (
        <div className="treeview-component-container">
            {
                showSearch &&
                <div className="search-row">
                    <OutlinedInput
                        size="small"
                        placeholder="Search"
                        className="search-menu-items-input"
                        fullWidth
                        startAdornment={
                            <InputAdornment position="start">
                                <Search size="small" />
                            </InputAdornment>
                        }
                        value={searchTermText}
                        onChange={(event) => searchTermSubject$.next(event.target.value)}
                    />
                </div>
            }
            {
                filteredItems?.length > 0 ?
                    <MaterialTreeView
                        expanded={searchTerm ? expandedSearchNodes : expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        defaultCollapseIcon={<TriangleDown size="medium" />}
                        defaultExpandIcon={<TriangleRight size="medium" />}
                        multiSelect={multiSelect ? true : null}
                    >
                        {filteredItems.map(renderNode)}
                    </MaterialTreeView> :
                    <div className="no-items">No results found.</div>
            }

        </div>
    );
}

export { TreeView };