import React from "react";
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import Users from "./Users/Users";

export const menuItems = [{
    id: "administration",
    title: "Administration",
    children: [{
        id: "users",
        title: "Users",
        url: "/admin/users"
    }]
}];

export default function Admin(props) {
    return (
        <Routes>
            <Route index element={<Navigate to="/admin/users" />} />
            <Route path="users" element={<Users />} />
        </Routes>
    );
}