import "./GeoMapContainer.scss";
import React, { useEffect, useState, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { GeoMap, GeoMapViewComponent } from "./map";
import { GeoClient } from "./services/geoClient";
import {
  GeoMarkerModel,
  GeoPolygonModel,
  PolygonFeature,
  PolygonFeatureCollection,
} from "./services/types";
import { Dialog } from "./shared/dialogs/dialog";
import { DataState } from "./services/enums";
import { AxiosConfig } from "./services/config/axios";
import { Button, TextField } from "../../../shared/components";
import { Loader } from "./shared/loader/loader";
import { useLoaderStore } from "./shared/loader/store";
import { convertPathToPolygon, convertPolygonToPath } from "./services/utils";
import { useAuth0 } from "../../../auth/AuthContext";

export interface GeoMapContainerProps {
  mapApiKey: string;
  baseUrl: string;
  zoomToBounds: number[][];
}

declare type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];
const libraries: Libraries = ["drawing"];

const GeoMapContainer: React.FunctionComponent<GeoMapContainerProps> = (
  props
) => {
  const { zoomToBounds } = props;
  const mapRef = useRef<GeoMapViewComponent>(null);
  const [markers, setMarkers] = useState<GeoMarkerModel[]>([]);
  const [polygons, setPolygons] = useState<GeoPolygonModel[]>([]);
  const [maxMarkers, setMaxMarkers] = useState<number>(1000);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: props.mapApiKey,
    libraries: libraries,
  });
  const { getTokenSilently } = useAuth0();
  const showLoader = useLoaderStore((state) => state.show);
  const hideLoader = useLoaderStore((state) => state.hide);

  useEffect(() => {
    getTokenSilently().then((token) => {
      AxiosConfig.init(props.baseUrl, token);
    });
  }, [getTokenSilently]);

  function fetchPolygons(bounds: number[][]) {
    showLoader();

    const geoClient = new GeoClient();
    geoClient
      .getGeoFeatures(
        "5ojpM3kBeEDU79rWAAybm56h9TEbnDwMTmGoIat1LT7KYKScs/Qgmg==",
        bounds
      )
      .then((response) => {
        const features = response.data.features;
        const newPolygons = features.map((feature) => {
          const path = convertPolygonToPath(feature.geometry.coordinates);

          return {
            id: feature.id,
            name: feature.properties?.["name"],
            site: feature.properties?.["site"],
            poiType: feature.properties?.["poiType"],
            tat: feature.properties?.["tat"],
            lane: feature.properties?.["lane"],
            path,
            dataState: DataState.unchanged,
          } as GeoPolygonModel;
        });
        setPolygons(newPolygons);

        hideLoader();
      });
  }

  function fetchGps(bounds: number[][]) {
    showLoader();

    const geoClient = new GeoClient();
    geoClient
      .getGpsData(
        "tkejbn/Xl82buqyvWKF0trCEXxq3oWw58RLIQsMjeG7VRw9FVoCR3g==",
        bounds,
        maxMarkers || 1000
      )
      .then((response) => {
        const newMarkers = response.data.map((data, index) => {
          return {
            id: "" + index,
            // todo: Refactor this number formatting into a utility function...
            name: `${data.registration}\n\nSpeed: ${data.speed?.toLocaleString(
              undefined,
              { maximumFractionDigits: 0 }
            )} km/h\nTime to Previous Reading: ${data.time_to_prev_reading_mins?.toLocaleString(
              undefined,
              { minimumFractionDigits: 1, maximumFractionDigits: 1 }
            )} Minutes`,
            lat: data.latitude,
            lng: data.longitude,
            color: data.speed === 0 ? "red" : "green",
            size: 4,
          } as GeoMarkerModel;
        });
        setMarkers(newMarkers);

        hideLoader();
      });
  }

  function handlePolygonAdded(polygon: GeoPolygonModel) {
    setPolygons((prevPolygons) => {
      return [...prevPolygons, polygon];
    });
  }

  function handlePolygonUpdated(polygon: GeoPolygonModel) {
    setPolygons((prevPolygons) => {
      return prevPolygons.map((item) => {
        if (item.id !== polygon.id) return item;

        // Set the data state to modified
        const dataState =
          item.dataState === DataState.unchanged
            ? DataState.modified
            : item.dataState;

        return { ...item, ...polygon, dataState };
      });
    });
  }

  function handlePolygonDeleted(id: string) {
    mapRef.current?.saveSettings();

    const geoClient = new GeoClient();
    geoClient.deleteGeo(
      "ZApHJK5uqiW0vpidFSWexqCwITAw4J/gN2QR/ZpXYqwza/7maDN5mw==",
      id
    );
    setPolygons((prevPolygons) => {
      return prevPolygons.filter((item) => item.id !== id);
    });
  }

  function handleLoadPolygons() {
    if (!mapRef.current) return;
    mapRef.current?.saveSettings();

    const bounds = mapRef.current.getBounds();
    if (!bounds) return;

    fetchPolygons(bounds);
  }

  function handleLoadGps() {
    if (!mapRef.current) return;
    mapRef.current?.saveSettings();

    const bounds = mapRef.current.getBounds();
    if (!bounds) return;

    fetchGps(bounds);
  }

  function handleSave() {
    mapRef.current?.saveSettings();

    const model = getChanges();
    if (model.features.length === 0) return;

    showLoader();
    const geoClient = new GeoClient();
    geoClient
      .upsertGeo(
        "3dKXpAPlwnmISfycTq4drS6cBhJAIE/3LodUvP0YlbC19Y/eiEDiRg==",
        model
      )
      .then((_) => {
        acceptChanges();

        hideLoader();
      });
  }

  function getChanges(): PolygonFeatureCollection {
    const featureChanges = polygons
      .filter(
        (x) =>
          x.dataState === DataState.added || x.dataState === DataState.modified
      )
      .map((x) => {
        const coords = convertPathToPolygon(x.path);

        const item: PolygonFeature = {
          type: "Feature",
          id: x.id,
          geometry: {
            type: "Polygon",
            coordinates: coords,
          },
          properties: {
            name: x.name,
            site: x.site,
            poiType: x.poiType,
            tat: x.tat,
            lane: x.lane,
          },
        };

        return item;
      });

    const features: PolygonFeatureCollection = {
      type: "FeatureCollection",
      features: featureChanges,
    };

    return features;
  }

  function acceptChanges() {
    setPolygons((prevPolygons) => {
      return prevPolygons
        .filter((item) => item.dataState !== DataState.deleted)
        .map((item) => ({ ...item, dataState: DataState.unchanged }));
    });
  }

  function handleMaxMarkersChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMaxMarkers(event.target.value as unknown as number);
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) return <></>;

  return (
    <React.Fragment>
      <GeoMap
        ref={mapRef}
        markers={markers}
        polygons={polygons.filter((x) => x.dataState !== DataState.deleted)}
        zoomToBounds={zoomToBounds}
        onPolygonAdded={handlePolygonAdded}
        onPolygonUpdated={handlePolygonUpdated}
        onPolygonDeleted={handlePolygonDeleted}
      />

      <div className="d-flex justify-content-end spaced-top">
        <TextField
          className="textfield spaced-right"
          label="Max Marker Count"
          variant="outlined"
          value={maxMarkers}
          onChange={handleMaxMarkersChange}
        />
        <Button
          className="spaced-right"
          variant="contained"
          color="secondary"
          onClick={handleLoadGps}
        >
          Load GPS
        </Button>
        <Button
          className="spaced-right"
          variant="contained"
          color="secondary"
          onClick={handleLoadPolygons}
        >
          Load Polygons
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>

      <Dialog />
      <Loader />
    </React.Fragment>
  );
};

export default GeoMapContainer;
