import * as d3 from "d3";
import * as _ from "lodash";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { VdtNode, ValueDriverTreeNode } from "./VdtNode";

const COLLAPSE_EXPAND_BUTTON_OVERFLOW_BOTTOM = 12;

export interface ValueDriverTreeOptions {
    width?: number;
    height?: number;
    nodeWidth?: number;
    nodeHeight?: number;
    boxRadius?: number;
    nodeTitleHeight?: number;
    siblingSpacing?: number;
    parentSpacing?: number;
    neighbourSpacingFactor?: number;
    onExpand?: (event: any) => void;
    onCollapse?: (event: any) => void;
    duration?: number;
    allowDrag?: boolean;
    horizontalLayout?: boolean;
    displayModeTransitionLevel?: number;
    onZoomLevelChanged?: (zoomLevel: number) => void;
    zoomToFitOnNodeToggle?: boolean;
    showProgressBarInSimpleMode?: boolean;
    staticPlot?: boolean;
    onFieldValueEdited?: ({ node: any, field: ValueDriverTreeNodeField }) => void;
}


export class ValueDriverTree<TModel> {
    public selectedNode: ValueDriverTreeNode<TModel>;
    public root: ValueDriverTreeNode<TModel>;
    public nodes: ValueDriverTreeNode<TModel>[];
    public allowDrag: boolean;
    private tree: any;
    private container: any;
    public width: number;
    public height: number;
    private nodeWidth: number;
    private nodeHeight: number;
    private siblingSpacing: number;
    private parentSpacing: number;
    private neighbourSpacingFactor?: number;
    private displayModeTransitionLevel: number;
    private mode: "full" | "simple";
    private onExpand: (event: any) => void;
    private onCollapse: (event: any) => void;
    private onZoomLevelChanged: (newZoomLevel: number) => void;
    public duration: number;
    private zoomListener: any;
    private lastClickTarget: any;
    public scale: number;
    public zoomToFitOnNodeToggle: boolean;
    private showProgressBarInSimpleMode: boolean;
    private staticPlot: boolean;
    private onFieldValueEdited?: ({ node: any, field: ValueDriverTreeNodeField }) => void;

    constructor(
        private selector: string | Element,
        private options: ValueDriverTreeOptions) {

        this.width = options.width || 800;
        this.height = options.height || 600;
        this.nodeWidth = options.nodeWidth || 200;
        this.nodeHeight = options.nodeHeight || 120;
        this.siblingSpacing = options.siblingSpacing || (options.horizontalLayout ? 20 : 40);
        this.parentSpacing = options.parentSpacing || (options.horizontalLayout ? 100 : 40);
        this.parentSpacing = this.parentSpacing < 40 ? 40 : this.parentSpacing;
        this.neighbourSpacingFactor = options.neighbourSpacingFactor || 0.2;
        this.onExpand = options.onExpand;
        this.onCollapse = options.onCollapse;
        this.onZoomLevelChanged = options.onZoomLevelChanged;
        this.duration = options.duration ?? 750;// duration of transitions in ms
        this.allowDrag = options.allowDrag || false;
        this.displayModeTransitionLevel = options.displayModeTransitionLevel ?? 0.8;
        this.mode = "simple" // This will get overridden by the zoom level anyway, but we need a default value for the initial layout...
        this.zoomToFitOnNodeToggle = options.zoomToFitOnNodeToggle ?? true;
        this.showProgressBarInSimpleMode = options.showProgressBarInSimpleMode ?? true;
        this.staticPlot = !!options.staticPlot;
        this.onFieldValueEdited = options.onFieldValueEdited;

        this.zoomListener = d3.behavior.zoom()
            .scaleExtent([0.1, 8])
            .on("zoom", this.zoom.bind(this));
        if (options.horizontalLayout) {
            // All translations are centered around the center point of the root node of the tree.
            // So we move the tree down such that the root node is vertically centered and we move it to
            // the right just enough so the entire root node is visible...
            this.zoomListener.translate([this.nodeWidth / 2, this.height / 2]);
        }
        else {
            this.zoomListener.translate([this.width / 2, this.nodeHeight / 2]);
        }

        this.initTree();
        this.zoomTo(1);
    }

    private initTree() {
        d3.select(this.selector as any).selectAll("svg").remove();

        this.container = d3.select(this.selector as any)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .call(this.zoomListener)
            .append('g');

        this.tree = d3.layout.tree()
            .nodeSize(this.options.horizontalLayout ? [this.nodeHeight + this.siblingSpacing, this.nodeWidth + this.parentSpacing] : [this.nodeWidth + this.siblingSpacing, this.nodeHeight + this.parentSpacing])
            .separation((a, b) => { return a.parent === b.parent ? 1 : (1 + this.neighbourSpacingFactor); })
            .children((node) => {
                if (node["collapsed"]) {
                    return null;
                }
                else {
                    return node["nodes"];
                }
            });
    }

    private redrawTreeInNewMode(mode: "full" | "simple") {
        this.mode = mode;

        if (!this.root) return;

        // The draw function is normally used for collapse/expand redraws, but we're (ab)using it here to redraw the tree when the display mode changes.
        // If this call happens during a collapse/expand animation, the "parent" for the animation will be changed if we just pass in an arbitrary node,
        // so we store the last click target in the toggle handler and use it here, with root as a fallback...
        this.draw(this.lastClickTarget ?? this.root);
    }

    private zoom() {
        const scale = d3.event["scale"];

        // Notify subscribers if zoom level has changed...
        if (this.scale !== scale) {
            this.onZoomLevelChanged && this.onZoomLevelChanged(scale);
            this.scale = scale;
        }

        if (this.mode === "simple" && scale >= this.displayModeTransitionLevel) {
            this.redrawTreeInNewMode("full");
        } else if (this.mode === "full" && scale < this.displayModeTransitionLevel) {
            this.redrawTreeInNewMode("simple");
        }

        this.container.attr("transform", "translate(" + d3.event["translate"] + ") scale(" + d3.event["scale"] + ")");
    }

    public zoomOut() {
        let scale = this.zoomListener.scale();
        scale -= 0.25;

        this.zoomTo(scale);
    }

    public zoomIn() {
        let scale = this.zoomListener.scale();
        scale += 0.25;

        this.zoomTo(scale);
    }

    public zoomTo(scale) {
        let extent = this.zoomListener.scaleExtent();

        if (scale < extent[0] || scale > extent[1]) return;

        this.zoomListener.scale(scale);
        this.zoomListener.event(this.container);
    }

    public zoomToFit() {
        let allNodes = this.tree.nodes(this.root);
        let xMax = _.chain(allNodes).map(n => n.x + this.nodeWidth / 2).max().value();
        let xMin = _.chain(allNodes).map(n => n.x - this.nodeWidth / 2).min().value();
        let yMax = _.chain(allNodes).map(n => n.y + this.nodeHeight / 2).max().value() + COLLAPSE_EXPAND_BUTTON_OVERFLOW_BOTTOM;
        let yMin = _.chain(allNodes).map(n => n.y - this.nodeHeight / 2).min().value();
        let yExtent = yMax - yMin;
        let xExtent = xMax - xMin;

        let scale = Math.min(this.width / (xMax - xMin), this.height / (yMax - yMin)) * 0.95; // Leave 5% whitespace on the sides after zooming...

        this.zoomListener.scale(scale);

        if (this.options.horizontalLayout) {
            this.zoomListener.translate([this.nodeWidth / 2 * scale, this.height / 2]);
        }
        else {
            // If the scaling is constrained by the width, center the chart vertically.  We do this by moving the chart down by (open space / 2), where open space = container height - scaled chart height.
            // If constrained by the height, just move the top node fully into view (box height / 2)...
            this.zoomListener.translate([xExtent * scale < this.width ? (this.width / scale - xMax - xMin) * scale * 0.5 : (xExtent - xMax - xMin) * scale * 0.5, yExtent * scale < this.height ? (this.nodeHeight * scale + this.height - (yExtent * scale)) / 2 : (this.nodeHeight * scale / 2)]);
        }

        this.zoomListener.event(this.container);
    }

    updateBoxSize(width: number, height: number) {
        this.nodeWidth = width || this.nodeWidth;
        this.nodeHeight = height || this.nodeHeight;

        if (this.options.horizontalLayout) {
            this.tree.nodeSize([this.nodeHeight + 20, this.nodeWidth + 100]);
        }
        else {
            this.tree.nodeSize([this.nodeWidth + 60, this.nodeHeight + 40]);
        }
    }

    public drawLinks(source: d3.layout.tree.Node) {
        let unmodifiedNodes = this.tree.nodes(this.root);
        let unmodifiedLinks = this.tree.links(unmodifiedNodes);

        // Update links
        let links = this.container.selectAll("path.vdtLink")
            .data(unmodifiedLinks, function (d) { return d.target.id; });

        // Add new links   
        // Transition new links from the source's
        // old position to the links final position
        links
            .style('fill-opacity', 0)
            .enter()
            .append("path")
            .attr("class", (props) => `vdtLink ${props.target.class}`)
            .attr("d", (d) => {
                if (this.options.horizontalLayout) {
                    let o = { x: source.x, y: (source.y + this.nodeWidth / 2) };
                    return this.transitionElbowH({ source: o, target: o });
                }
                else {
                    let o = { x: source.x, y: (source.y + this.nodeHeight / 2) };
                    return this.transitionElbowV({ source: o, target: o });
                }
            })
            .attr("opacity", (props) => ValueDriverTree.normalizeProgressBarValue(props.target.progressBarValue) + 0.1);

        // Update the old links positions
        links.transition()
            .duration(this.duration)
            .style('fill-opacity', 1)
            .attr("class", (props) => `vdtLink ${props.target.class}`)
            .attr("d", this.options.horizontalLayout ? this.elbowH.bind(this) : this.elbowV.bind(this));

        // Remove any links we don't need anymore
        // if part of the tree was collapsed
        // Transition exit links from their current position
        // to the source's new position
        links.exit()
            .transition()
            .duration(this.duration)
            .style('stroke-opacity', 0)
            .style('opacity', 0)
            .attr("d", (d) => {
                if (this.options.horizontalLayout) {
                    let o = { x: source.x, y: (source.y + this.nodeWidth / 2) };
                    return this.transitionElbowH({ source: o, target: o });
                }
                else {
                    let o = { x: source.x, y: (source.y + this.nodeHeight / 2) };
                    return this.transitionElbowV({ source: o, target: o });
                }
            })
            .remove();
    }

    public draw(source: d3.layout.tree.Node) {
        let self = this;
        this.drawLinks(source);

        let unmodifiedNodes = this.tree.nodes(this.root);

        // Update nodes
        let nodes = this.container.selectAll("g.vdtNode")
            .data(unmodifiedNodes, (node) => { return node.id; });

        // Add any new nodes
        let nodeEnter = nodes.enter().append("g")
            .attr('transform', (source) => {
                if (source.parent && source.parent.y0 != null && source.parent.x0 != null) {
                    if (this.options.horizontalLayout) {
                        return `translate(${source.parent.y0},${source.parent.x0})`;
                    }
                    else {
                        return `translate(${source.parent.x0}, ${source.parent.y0})`;
                    }
                }
                return "";
            })
            .attr("data-title", source => source.title);

        // Draw the node boxes...
        nodeEnter.append("foreignObject")
            .attr({
                "x": -this.nodeWidth / 2,
                "y": -this.nodeHeight / 2,
                "width": this.nodeWidth,
                "height": this.nodeHeight,
                "overflow": "visible"
            })
            .html((node) => `<div class="vdtNodeContainer"></div>`)
            .each(function (n) {
                const root = createRoot(this.children[0]);
                const expandCollapseCallback = self.toggleNode.bind(self);
                // Store the render function for each node, so that we can call it whenever the node needs to be updated...
                n.renderNode = () => {
                    root.render(
                        React.createElement(VdtNode, { node: n, expandCollapseCallback: expandCollapseCallback, zoomLevel: self.mode, staticPlot: self.staticPlot, onFieldValueEdited: self.onFieldValueEdited })
                    );
                }
            });

        // Set the class attributes
        nodes.attr("class", (node) => {
            const classes = ["vdtNode"];
            classes.push(this.mode);
            classes.push(ValueDriverTree.normalizeProgressBarValue(node.progressBarValue ?? 1) < 0.6 ? "low-opacity" : "high-opacity");
            node.class && classes.push(node.class);
            return classes.join(" ");
        });

        // (Re)render nodes to trigger the new zoom level, etc...
        nodes.each((n) => {
            n.renderNode();
        });

        // Update the position of both old and new nodes
        nodes.transition()
            .duration(this.duration)
            .attr("transform", (d) => {
                return this.options.horizontalLayout ?
                    "translate(" + d.y + "," + d.x + ")" :
                    "translate(" + d.x + "," + d.y + ")";
            });

        // Remove nodes we aren't showing anymore
        nodes.exit()
            .transition()
            .duration(this.duration)
            .style('fill-opacity', 0)
            .style('opacity', 0)
            .attr("transform", (d) => {
                return this.options.horizontalLayout ?
                    "translate(" + source.y + "," + source.x + ")" :
                    "translate(" + source.x + "," + source.y + ")";
            })
            .remove();

        // Stash the old positions for transition.
        unmodifiedNodes.forEach((source) => {
            source.x0 = source.x;
            source.y0 = source.y;
        });
    }

    private static normalizeProgressBarValue(value) {
        return Math.max(0, Math.min(1, value));
    }

    public toggleAllNodes(mode: "expand" | "collapse") {
        const toggleAllNodesImpl = (node: ValueDriverTreeNode<any>) => {
            node.collapsed = mode === "collapse";

            for (var child of node.nodes) {
                toggleAllNodesImpl(child);
            }
        }

        toggleAllNodesImpl(this.root);
        this.draw(this.root as any);

        this.zoomToFit();
    }

    /**
     * Update a node's state when it is clicked.
     */
    private toggleNode(node) {
        this.lastClickTarget = node;
        node.collapsed = !node.collapsed;

        if (node.collapsed) {
            if (this.onCollapse) this.onCollapse(node);
        } else {
            if (this.onExpand) this.onExpand(node);
        }

        this.draw(node);

        if (this.zoomToFitOnNodeToggle) {
            this.zoomToFit();
        }
    }

    /**
     * Custom path function that creates straight connecting
     * lines. Calculate start and end position of links.
     * Instead of drawing to the center of the node,
     * draw to the border of the box.
     * That way drawing order doesn't matter. In other
     * words, if we draw to the center of the node
     * then we have to draw the links first and the
     * draw the boxes on top of them.
     */
    private elbowH(d) {
        let sourceX = d.source.x, sourceY = d.source.y + (this.nodeWidth / 2), targetX = d.target.x, targetY = d.target.y - (this.nodeWidth / 2);
        return "M" + sourceY + "," + sourceX
            + "H" + (sourceY + (targetY - sourceY) / 2)
            + "V" + targetX
            + "H" + targetY;
    }

    private elbowV(d) {
        const sourceX = d.source.x;
        let sourceY = d.source.y + (this.nodeHeight / 2);
        if (d.source.children?.length > 0) sourceY += COLLAPSE_EXPAND_BUTTON_OVERFLOW_BOTTOM;

        const targetX = d.target.x;
        const targetY = d.target.y - (this.nodeHeight / 2);
        return "M" + sourceX + "," + sourceY
            + "V" + (sourceY + (targetY - sourceY) / 2)
            + "H" + targetX
            + "V" + targetY;
    }

    /**
     * Use a different elbow function for enter
     * and exit nodes. This is necessary because
     * the function above assumes that the nodes
     * are stationary along the x axis.
     */
    private transitionElbowH(d) {
        return "M" + d.source.y + "," + d.source.x
            + "H" + d.source.y
            + "V" + d.source.x
            + "H" + d.source.y;
    }

    private transitionElbowV(d) {
        return "M" + d.source.x + "," + d.source.y
            + "V" + d.source.y
            + "H" + d.source.x
            + "V" + d.source.y;
    }
}