import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import * as _ from "lodash";

interface Props {
    onEnter: () => void;
    onExit: () => void;
    parameterValues: Map<string, any>;
    currentFrame: Object;
    onParameterValuesChanged: () => void;
    onParameterValueChanged: (parameterName: string, newValue: any) => void;
    onFrameDataChanged: () => void;
    onFieldValueChanged: (fieldName: string, newValue: any) => void;
}

function CanvasEventHandler(props: Props) {
    const { onEnter, onExit, parameterValues, currentFrame, onParameterValuesChanged, onParameterValueChanged, onFrameDataChanged, onFieldValueChanged } = props;
    const [lastParameterValues, setLastParameterValues] = React.useState(parameterValues);
    const [lastFrameData, setLastFrameData] = React.useState(currentFrame);

    React.useEffect(() => {
        onEnter && onEnter();
        return onExit;
    }, []);

    React.useEffect(() => {
        onParameterValuesChanged && onParameterValuesChanged();
    }, [parameterValues, onParameterValuesChanged]);

    React.useEffect(() => {
        if (!parameterValues || !onParameterValueChanged) return;

        for (let key of parameterValues.keys()) {
            const newValue = parameterValues.get(key);

            if (!lastParameterValues.has(key)) {
                onParameterValueChanged?.(key, newValue);
            } else {
                const oldValue = lastParameterValues.get(key);

                if (!_.isEqual(oldValue, newValue)) {
                    onParameterValueChanged?.(key, newValue);
                }
            }
        }

        setLastParameterValues(new Map(parameterValues));
    }, [parameterValues, onParameterValueChanged]);

    React.useEffect(() => {
        if (!currentFrame) return;
        if (!onFrameDataChanged && !onFieldValueChanged) return;

        if (!_.isEqual(lastFrameData, currentFrame)) {
            onFrameDataChanged && onFrameDataChanged();

            for (let [key, newValue] of Object.entries(currentFrame)) {
                if (!Object.keys(lastFrameData).find(k => k === key)) {
                    onFieldValueChanged?.(key, newValue);
                } else {
                    const oldValue = lastFrameData?.[key];

                    if (!_.isEqual(oldValue, newValue)) {
                        onFieldValueChanged?.(key, newValue);
                    }
                }
            }
        }

        setLastFrameData(_.clone(currentFrame));
    }, [currentFrame, onFrameDataChanged, onFieldValueChanged]);

    return null;
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        currentFrame: state.storyline.currentFrame.frame
    }),
    null)(CanvasEventHandler);