import React from "react";
import Storyline from "./Storyline/Storyline";
import Home from "./Home/Home";
import * as _ from "lodash";
import {
    Routes,
    Route,
    Navigate,
    useParams,
    useLocation
} from "react-router-dom";
import { connect } from "react-redux";
import { loadStoryline, loadCanvas } from "../store/storyline/actions";
import { setMenuIsExpanded } from "../store/app/actions";
import { RootState } from "../store";
import { CircularProgress, AppInsightsErrorBoundary } from "../shared/components";
import ErrorPage from "../shared/pages/Error";
import { MenuItemDisplayModel } from "../shared/api-client";
import useQueryParams from "../shared/hooks/useQueryParams";
import clsx from "clsx";
import Sidenav from "../shared/components/Sidenav";
import SidenavMiniMap from "./SidenavMiniMap/SidenavMiniMap";

interface ViewerProps {
    loading: boolean,
    menuItems: MenuItemDisplayModel[],
    parameterValues: Map<string, any>,
    loadStoryline: typeof loadStoryline,
    loadCanvas: typeof loadCanvas;
    setMenuIsExpanded: typeof setMenuIsExpanded
}

function NavigateToHomeOrFirstDirectLink(props: { menuItems: MenuItemDisplayModel[] }) {
    const { menuItems } = props;
    const directLinks = _.chain(menuItems).flatMapDeep(mi => [mi, ...mi.children]).filter(mi => !mi?.children?.length).value();

    return directLinks.length !== 1 ?
        <Home /> :
        <Navigate to={directLinks[0].url} />
}

function RenderStoryline(props: { loader: (string) => any }) {
    const { loader } = props;
    const { id } = useParams();

    loader(id);

    return (
        <AppInsightsErrorBoundary onError={(e) => <ErrorPage {...e} />}>
            <Storyline id={id} />
        </AppInsightsErrorBoundary>
    )
}

function Viewer(props: ViewerProps) {
    const { loading, parameterValues, menuItems, loadStoryline, loadCanvas, setMenuIsExpanded } = props;
    const visibilityClasses = [
        String(parameterValues.get("isExport")) === "1" ? "is-export" : undefined,
        String(parameterValues.get("hideTitleBar")) === "1" ? "hide-titlebar" : undefined,
        String(parameterValues.get("hideMinimap")) === "1" ? "hide-minimap" : undefined,
        String(parameterValues.get("hideCommentary")) === "1" ? "hide-commentary" : undefined
    ];
    const [currentLocation, setCurrentLocation] = React.useState(null);

    const queryParams = useQueryParams();
    const location = useLocation();

    const ensureCorrectStorylineIsLoaded = (location: Object, loader: typeof loadStoryline | typeof loadCanvas) => (id: string) => {
        if (!_.isEqual(location, currentLocation)) {
            // Remove the "raw value" prefix used to work around the automatic type conversion done by the URL parser...
            for (const [key, value] of Array.from(queryParams?.entries())) {
                if (typeof value === "string" && value.startsWith("\\")) {
                    queryParams.set(key, value.substring(1));
                }
            }

            if (queryParams.has("menu-expanded")) {
                const expandedParamValue = queryParams.get("menu-expanded");
                setMenuIsExpanded(expandedParamValue === 1 || expandedParamValue === "true");
            }

            loader(id, queryParams);
            setCurrentLocation(location);
        }
    }

    return (
        <>
            <Sidenav menuItems={menuItems} activeItemContent={<SidenavMiniMap />} className={clsx(visibilityClasses)} />
            <div className={clsx("content", visibilityClasses)}>
                {
                    loading ?
                        <CircularProgress className="loading-spinner" /> :
                        <Routes>
                            <Route index element={<NavigateToHomeOrFirstDirectLink menuItems={menuItems} />} />
                            <Route path={`storylines/:id`} element={<RenderStoryline loader={ensureCorrectStorylineIsLoaded(location, loadStoryline)} />} />
                            <Route path={`canvases/:id`} element={<RenderStoryline loader={ensureCorrectStorylineIsLoaded(location, loadCanvas)} />} />
                        </Routes>
                }
            </div>
        </>
    );
}

export default connect(
    (state: RootState) => ({
        loading: state.app.loading,
        menuItems: state.app.menuItems,
        parameterValues: state.storyline.parameterValues
    }),
    { loadStoryline: loadStoryline as any, loadCanvas: loadCanvas as any, setMenuIsExpanded })(Viewer);