import * as React from "react";
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails } from "@mui/material";
import { Plus, Dash } from "./icons";


interface AccordionProps {
    expandedGroup?: string;
    children: Array<JSX.Element> | JSX.Element;
}

const Accordion: React.FunctionComponent<AccordionProps> = (props: AccordionProps) => {
    const [expanded, setExpanded] = React.useState<string | false>(props.expandedGroup);
    const setExpandedWithSupportForCollapsingFinalPanel = (panelName: string) => {
        if (expanded === panelName) {
            setExpanded("");
        } else {
            setExpanded(panelName);
        }
    }

    const renderedChildren = React.Children.map(props.children, (child) =>
        React.cloneElement(child, { expanded, setExpanded: setExpandedWithSupportForCollapsingFinalPanel })
    );

    return <div className="accordion">
        {renderedChildren}
    </div>
};


interface AccordionGroupProps {
    title: string;
    children: any;
    expanded?: string;
    setExpanded?: (string) => void;
}

const AccordionGroup: React.FunctionComponent<AccordionGroupProps> = (props: AccordionGroupProps) => {
    const { title, children, expanded, setExpanded } = props;
    const isExpanded = expanded === title;
    const expandIcon = isExpanded ? <Dash /> : <Plus />;

    return <MuiAccordion expanded={isExpanded} onChange={() => setExpanded(title)}>
        <MuiAccordionSummary
            expandIcon={expandIcon}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
        >
            {title}
        </MuiAccordionSummary>
        <MuiAccordionDetails>
            {children}
        </MuiAccordionDetails>
    </MuiAccordion>
};


export { Accordion, AccordionGroup };
export type { AccordionGroupProps, AccordionProps };
