import "./PersistentTabPanel.scss";
import React from 'react';
import { RootState } from "../../../store";
import { useSelector } from 'react-redux'

interface TabPanelProps {
    tabsName: string,
    value: string,
    children: any,
    className: string
}

function PersistentTabPanel(props: TabPanelProps) {
    const { tabsName, value, children, className, ...other } = props;
    const parameterValues = useSelector((state: RootState) => state.storyline.parameterValues);

    const parameterName = `tabs-${tabsName}`;

    const [activeTab, setActiveTab] = React.useState(parameterValues.get(parameterName));

    React.useEffect(() => {
        if (parameterValues.has(parameterName)) {
            const newValue = parameterValues.get(parameterName);

            if (newValue !== activeTab) {
                setActiveTab(newValue);
            }
        }
    }, [parameterValues]);

    return (
        <div {...other} className={`${className} persistent-tab ${activeTab === value ? "" : "persistent-tab-hidden"}` }>
                {children}
            </div>
        );
}

export default PersistentTabPanel;