import "./KpiProfile.scss";
import clsx from "clsx";
import { ContentRenderer } from "../ContentRenderer";
import Plot from "react-plotly.js";
import useResizeAware from 'react-resize-aware';

const chartStyle = { width: "100%", height: "100%", flexGrow: 1, flexShrink: 1, flexBasis: 1 };

interface KpiProfileProps {
    variant?: "compact" | "full";
    title?: string;
    delta?: string;
    value?: string;
    chart?: { data: Plotly.Data[], layout: Plotly.Layout };
    className?: string;
    children?: any;
}

const defaultProps: KpiProfileProps = {
    variant: "full"
}

function KpiProfile(props: KpiProfileProps) {
    const { variant, title, delta, value, chart, className, children, ...rest } = props;
    const [resizeListener] = useResizeAware();

    return (
        <div {...rest} className={clsx("kpi-profile", variant, className)}>
            <div className="title">{<ContentRenderer input={title} />}</div>
            <div className="delta">{<ContentRenderer input={delta} />}</div>
            <div className="value">{<ContentRenderer input={value} />}</div>
            <div className="chart">
                {
                    children ||
                    <>
                        {resizeListener}
                        <Plot
                            data={chart?.data}
                            layout={chart?.layout}
                            config={{ displaylogo: false, responsive: true, autosizable: true, staticPlot: false, displayModeBar: false }}
                            style={chartStyle}
                            />
                    </>
                }
            </div>
        </div>
    );
}

KpiProfile.defaultProps = defaultProps;

export default KpiProfile;