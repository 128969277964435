import clsx from "clsx";
import { ValueDriverTreeNode, VdtNode } from "../../../shared/components";

export default function VdtNodeCard(props?: ValueDriverTreeNode<any>) {
    const node = { ...props };
    const className = props.class || props["className"]; // The JSX parser normalizes the "class" property if passsed in directly, so we need to look for both forms...

    return (
        <div className={clsx("vdtNode", "full", className)}>
            <div className="vdtNodeContainer">
                <VdtNode
                    node={node}
                    zoomLevel="full"
                    staticPlot={false}
                />
            </div>
        </div>
    );
}