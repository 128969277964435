import React from 'react';
import "./TextToSpeechPlayer.scss";
import { useSettings } from '../../../shared/providers/SettingsProvider';
import { getUserPreferences } from "../../../shared/services/userPreferencesService";
import { sanitizeTextForNarration } from "../../../shared/utilities";
import { Fab, Tooltip } from "../../../shared/components";
import { Unmute, Pause, Clock } from "../../../shared/components/icons";

export default function TextToSpeechPlayer(props: { text: string | string[], [key: string]: any }) {
    const { text } = props;
    const settings = useSettings();
    const [audio, setAudio] = React.useState<HTMLAudioElement>(null);
    const [state, setState] = React.useState("paused");

    if (!text || !settings.googleApiKey) return null;

    const playOrPauseClicked = () => {
        if (!audio) {
            setState("loading");

            const sanitizedText = sanitizeTextForNarration(text);

            fetch(`https://texttospeech.googleapis.com/v1/text:synthesize?key=${settings.googleApiKey}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        input: {
                            text: sanitizedText
                        },
                        voice: getUserPreferences().voice,
                        audioConfig: {
                            audioEncoding: "LINEAR16",
                            pitch: 0,
                            speakingRate: 1
                        }
                    })
                }).then(result => {
                    result.json().then((responseBody) => {
                        let newAudio = new Audio();
                        newAudio.onpause = () => setState("paused");
                        newAudio.onplay = () => setState("playing");

                        newAudio.src = `data:audio/mpeg;base64,${responseBody?.audioContent}`;
                        newAudio.play();
                        setAudio(newAudio);
                    })
                });
        }
        else if (!audio.paused) {
            audio.pause();
        }
        else {
            audio.play();
        }
    }

    return (
        <Tooltip arrow title={state === "paused" ? "Narrate" : "Pause"}>
            <Fab className="text-to-speech-control" size="small" color="primary" onClick={playOrPauseClicked} {...props}>
                {
                    state === "paused" ? <Unmute size="small" color="white" /> :
                        state === "loading" ? <Clock size="small" color="white" /> :
                            state === "playing" ? <Pause size="small" color="white" /> :
                                null
                }
            </Fab>
        </Tooltip>
    );
}