import "./MinMaxIndicator.scss";
import clsx from "clsx";
import { ProgressBar } from "../../../shared/components";
import * as _ from "lodash";
import * as colors from "../../../shared/colors";

interface MinMaxIndicatorProps {
    range?: [number, number];
    minVal?: number;
    maxVal?: number;
    negativeColor?: string;
    positiveColor?: string;
    className?: string;
}

function KpiProfile(props: MinMaxIndicatorProps) {
    const { range, minVal, maxVal, negativeColor, positiveColor, className, ...rest } = props;

    return (
        <div {...rest} className={clsx("min-max-indicator", className)}>
            <div className="progressbar left">
                <ProgressBar value={Math.abs(minVal)} maxValue={Math.abs(range?.[0])} text=" " fillColor={negativeColor} dir="rtl" />
            </div>
            <div className="progressbar right">
                <ProgressBar value={Math.abs(maxVal)} maxValue={Math.abs(range?.[1])} text=" " fillColor={positiveColor} />
            </div>
        </div>
    );
}

KpiProfile.defaultProps = {
    range: [-1, 1],
    negativeColor: colors.chart_red_3,
    positiveColor: colors.chart_green_3
};

export default KpiProfile;