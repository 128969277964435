import React from 'react';
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { Tabs as BaseTabs } from "../../../shared/components";

interface TabsProps {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    defaultValue?: string;
    children: any;
}

function isChildOfTab(element: HTMLElement) {
    if (!element) return false;
    if (element.classList.contains("MuiTab-root")) return true;

    return isChildOfTab(element.parentElement);
}

function Tabs(props: TabsProps) {

    const { storyline, updateParameterValue, name, defaultValue, children, ...other } = props;

    const parameterName = `tabs-${name}`;

    const [value, setValue] = React.useState(defaultValue);

    React.useEffect(() => {
        if (!storyline.parameterValues.has(parameterName)) {
            setValue(defaultValue);
            updateParameterValue(parameterName, defaultValue);
        }
    }, [defaultValue]);

    const handleChange = (event: any, newValue: string) => {
        // Only treat Tab clicks as change events.
        // Allows us to embed interactive components inside Tabs without affecting the selected value...
        if (!isChildOfTab(event.target)) return;

        setValue(newValue);
        updateParameterValue(parameterName, newValue);
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(parameterName)) {
            const newValue = storyline.parameterValues.get(parameterName);

            if (newValue !== value) {
                setValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return (
        <BaseTabs
            value={value}
            onChange={handleChange}
            {...other}
        >
            {_.filter(children, c => c.type.toString().indexOf("fragment") === -1)}
        </BaseTabs>
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(Tabs);