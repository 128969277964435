import React from "react";
import { connect } from "react-redux";
import { updateCurrentSlideTemplate } from "../../store/storyline/actions";
import { showSuccess, showError } from "../../store/notifications/actions";
import { UnControlled as CodeMirror } from "react-codemirror2"
import "codemirror/mode/jsx/jsx";
import "codemirror/mode/css/css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import * as api from "../../shared/api-client";
import * as _ from "lodash";
import { DialogTitleWithCloseButton, AppBar, Button, Toolbar, Dialog, DialogContent, TextField, Checkbox, DialogActions, FormControlLabel } from "../../shared/components";
import { useForm, Controller } from "react-hook-form";
import { object, string, boolean } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Slide } from "../../store/storyline/types";

interface SaveTemplateDialogProps {
    canvasId: string,
    contents: string,
    customCss: string,
    isOpen: boolean,
    setIsOpen: (boolean) => void,
    showSuccess: typeof showSuccess,
    showError: typeof showError
}

function SaveTemplateDialog(props: SaveTemplateDialogProps) {
    const schema = object().shape({
        overwriteExistingTemplate: boolean(),
        newTemplateName: string()
            .when("overwriteExistingTemplate", {
                is: false,
                then: string().required("Please enter a name for the new template.")
            })
    });
    const { register, handleSubmit, errors, setError, control, watch } = useForm({ resolver: yupResolver(schema), defaultValues: { overwriteExistingTemplate: true, newTemplateName: null } });
    const [formErrors, setFormErrors] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const overwriteExistingTemplate = watch("overwriteExistingTemplate", true);

    const closeDialog = () => {
        props.setIsOpen(false);
    }

    const attachFormErrors = (errorDictionary: object) => {
        _.forOwn(errorDictionary, (value, key: any) => {
            if (key === "non_field_errors") {
                setFormErrors(value[0]);
            }
            else {
                setError(key, { type: "server", message: value[0] });
            }
        });
    }

    const save = (formModel: { overwriteExistingTemplate, newTemplateName }) => {
        setSaving(true);

        new api.CanvasesClient()
            .updateTemplate(props.canvasId, {
                newTemplateName: formModel.overwriteExistingTemplate ? null : formModel.newTemplateName,
                contents: props.contents,
                customCss: props.customCss
            })
            .then(() => {
                closeDialog();
                props.showSuccess(`Template ${formModel.overwriteExistingTemplate ? "updated" : "created"} successfully`);
            })
            .catch((error: api.ApiException) => {
                if (error.status === 400) {
                    attachFormErrors(JSON.parse(error.response));
                }
                else {
                    props.showError(error.response);
                }
            })
            .finally(() => {
                setSaving(false);
            });
    };


    return (
        <Dialog
            open={props.isOpen}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick') {
                    closeDialog();
                }
            }}
            aria-labelledby="form-dialog-title">
            <DialogTitleWithCloseButton onClose={closeDialog} id="form-dialog-title">
                Save Template
            </DialogTitleWithCloseButton>
            <form onSubmit={handleSubmit(save)}>
                <DialogContent>
                    <Controller
                        name="overwriteExistingTemplate"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={e => props.onChange(e.target.checked)}
                                    checked={props.value} />}
                                label="Overwrite Existing Template ?"
                            />
                        )}
                    />
                    <br />
                    <br />
                    {!overwriteExistingTemplate &&
                        <TextField
                            name="newTemplateName"
                            error={!!errors.newTemplateName}
                            label="New Template Name"
                            helperText={errors.newTemplateName?.message}
                            inputRef={register}
                            variant="outlined"
                            fullWidth
                        />
                    }

                    {
                        formErrors &&
                        <div className="form-errors">
                            {formErrors}
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary" disabled={saving}>
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

interface TemplateProps {
    slide: Slide,
    updateCurrentSlideTemplate: typeof updateCurrentSlideTemplate,
    showSuccess: typeof showSuccess,
    showError: typeof showError
}

function Template(props: TemplateProps) {
    const [editor, setEditor] = React.useState(null);
    const [cssEditor, setCssEditor] = React.useState(null);
    const [saveDialogIsOpen, setSaveDialogIsOpen] = React.useState(false);

    const applyTemplateChange = () => {
        props.slide.template.contents = editor.doc.getValue();
        props.slide.template.customCss = cssEditor.doc.getValue();
        props.updateCurrentSlideTemplate(editor.doc.getValue(), cssEditor.doc.getValue());
    }

    const saveTemplate = () => {
        props.slide.template.contents = editor.doc.getValue();
        props.slide.template.customCss = cssEditor.doc.getValue();
        props.updateCurrentSlideTemplate(editor.doc.getValue(), cssEditor.doc.getValue());
        setSaveDialogIsOpen(true);
    }

    return (
        <div className="fill">
            <div style={{ height: "60%", maxHeight: "60%" }}>
                <CodeMirror
                    className="fill"
                    value={props.slide.template.contents}
                    options={{
                        mode: "text/jsx",
                        lineNumbers: true,
                        indentUnit: 4,
                        theme: "material"
                    }}
                    editorDidMount={(editor) => {
                        setEditor(editor);
                        setTimeout(() => editor.execCommand("goDocStart"), 50);
                    }}
                />
            </div>
            <div style={{ height: "calc(40% - 64px)" }}>
                <div className="css-heading">CSS</div>
                <div style={{ height: "calc(100% - 20px)" }}>
                    <CodeMirror
                        className="fill"
                        value={props.slide.template.customCss || "// Custom CSS goes here..."}
                        options={{
                            mode: "text/css",
                            lineNumbers: true,
                            indentUnit: 4,
                            theme: "material"
                        }}
                        editorDidMount={(editor) => {
                            setCssEditor(editor);
                            setTimeout(() => editor.execCommand("goDocStart"), 50);
                        }}
                    />
                </div>
            </div>

            <SaveTemplateDialog
                canvasId={props.slide.canvasId}
                contents={editor?.doc?.getValue()}
                customCss={cssEditor?.doc?.getValue()}
                isOpen={saveDialogIsOpen}
                setIsOpen={setSaveDialogIsOpen}
                showSuccess={props.showSuccess}
                showError={props.showError}
            />

            <AppBar className="action-panel" position="sticky">
                <Toolbar>
                    <Button color="secondary" variant="contained" onClick={applyTemplateChange}>Apply Changes</Button>
                    <Button color="primary" variant="contained" className="save-changes" onClick={saveTemplate}>Save Template</Button>
                </Toolbar>
            </AppBar>
        </div >
    );

}

export default connect(
    null,
    { updateCurrentSlideTemplate, showSuccess, showError })(Template);