import "./Vdt.scss";
import React, { useState, useRef, useEffect } from "react";
import { ValueDriverTree, ValueDriverTreeOptions } from "./valueDriverTree";
import { ValueDriverTreeNode } from "./VdtNode";
import useResizeAware from 'react-resize-aware';
import * as _ from "lodash";
import { Slider, IconButton, Tooltip } from "../";
import { ZoomIn, ZoomOut, Tune, Visibility, VisibilityOff } from "@mui/icons-material";

function setExpandedStatus(node: any, targetNodeId: string, isExpanded: boolean) {
    if (node?.id === targetNodeId) {
        node.isExpanded = isExpanded;
        node.collapsed = !isExpanded;
    }
    else {
        if (node?.nodes) {
            _.forEach(node.nodes, child => setExpandedStatus(child, targetNodeId, isExpanded));
        }
    }
}

export interface VdtProps extends ValueDriverTreeOptions {
    root: ValueDriverTreeNode<any>,
    hideControls?: boolean,
    staticPlot: boolean
}

function Vdt(props: VdtProps) {
    const vdtRef = useRef(null);
    const [tree, setTree] = useState<ValueDriverTree<any>>();
    const [resizeListener, sizes] = useResizeAware();
    const [zoomLevel, setZoomLevel] = React.useState(1);
    const [showControls, setShowControls] = React.useState(false);

    const renderTree = () => {
        if (!props.root) {
            return;
        }

        const vdt = new ValueDriverTree(vdtRef.current, {
            ...props,
            width: vdtRef.current?.clientWidth,
            height: vdtRef.current?.clientHeight,
            // Persist the collapsed/expanded status to the original VDT node in order to persist across slide/frame transitions...
            onCollapse: (node) => setExpandedStatus(props.root, node.id, false),
            onExpand: (node) => setExpandedStatus(props.root, node.id, true),
            onZoomLevelChanged: setZoomLevel
        });
        vdt.root = JSON.parse(JSON.stringify(props.root));
        vdt.duration = 0; // Disable animation for initial render...
        vdt.draw(vdt.root as any);
        vdt.zoomToFit();
        vdt.duration = 750; // Re-enable animation for user-initiated events...
        setTree(vdt);
    };

    useEffect(() => { setTimeout(renderTree, 0) }, [props]);

    React.useEffect(() => {
        if (tree) {
            tree.width = vdtRef.current?.clientWidth;
            tree.height = vdtRef.current?.clientHeight;
            tree.duration = 0; // Disable animation for initial render...
            tree.zoomToFit();
            tree.duration = 750; // Re-enable animation for user-initiated events...
        }
    }, [sizes.width, sizes.height]);

    const handleZoomSliderChange = (_: Event, newValue: number) => {
        if (!tree) return;

        tree.zoomTo(newValue);
    }

    return (
        <div className="vdt-and-controls-container">
            <div className="vdt-container">
                {!props.staticPlot && resizeListener}
                <div className="vdt-inner" ref={vdtRef}>
                    {
                        !props?.root && <div className="col-fill centered error">
                            <h3 className="error-message">"root" property is empty/missing.  Please check the data source and template bindings.</h3>
                        </div>
                    }
                </div>
            </div>

            <div className="controls-container">
            {
                showControls &&
                <div className="zoom-controls">
                    <IconButton color="primary" onClick={() => tree.zoomTo(zoomLevel + 0.1)}>
                        <ZoomIn />
                    </IconButton>
                    <Slider min={0.1} max={8} step={0.1} value={zoomLevel} onChange={handleZoomSliderChange} orientation="vertical" />
                    <IconButton color="primary" onClick={() => tree.zoomTo(zoomLevel - 0.1)}>
                        <ZoomOut />
                    </IconButton>
                </div>
            }
            {
                !props.hideControls && (
                    <div className="vdt-controls">
                        <Tooltip arrow title={showControls ? "Hide Zoom Controls" : "Show Zoom Controls"}>
                            <IconButton color="primary" onClick={() => setShowControls(!showControls)}>
                                <Tune />
                            </IconButton>
                        </Tooltip>

                        <Tooltip arrow title="Expand all Nodes">
                            <IconButton color="primary" onClick={() => tree.toggleAllNodes("expand")}>
                                <Visibility />
                            </IconButton>
                        </Tooltip>

                        <Tooltip arrow title="Collapse all Nodes">
                            <IconButton color="primary" onClick={() => tree.toggleAllNodes("collapse")}>
                                <VisibilityOff />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            }
            </div>
        </div>
    );
}

export { Vdt };