import React from 'react';
import "./ConfirmationDialog.scss";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const ConfirmationDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirmation-dialog"
            className="confirmation-dialog"
        >
            <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    onClick={() => setOpen(false)}
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    variant="text"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color="warning"
                >
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;