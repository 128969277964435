import * as React from "react";
import JsxParser from "@recursive-robot/react-jsx-parser";
import { useCanvasBindings } from "../../../shared/providers/CanvasBindingsProvider";
import { components, blacklistedAttrs } from "../../Canvas/Canvas";

const renderError = ({ error }) => <span className="error-message">{`Template error: ${error}`}</span>;

interface TemplateRendererProps {
    template: string;
    data?: any;
}

function _TemplateRenderer(props: TemplateRendererProps) {
    const { template, data, ...rest } = props;
    const _bindings = useCanvasBindings();

    const defineFunction = React.useCallback((...args) => {
        return new Function(...args).bind({ ..._bindings, ...data }); // eslint-disable-line no-new-func
    }, [_bindings]);

    const bindings = { ..._bindings, ...data, defineFunction };

    return (
        <JsxParser
            bindings={bindings}
            components={components}
            jsx={template}
            disableKeyGeneration={false}
            blacklistedAttrs={blacklistedAttrs}
            renderError={renderError}
            renderInWrapper={false}
            key={template} // Force re-parse when template content is updated
            {...rest}
        />
    );
}

const TemplateRenderer = React.memo(_TemplateRenderer);

export { TemplateRenderer };