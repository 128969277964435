import * as React from "react";
import "./ProgressBar.scss";
import Style from "style-it";
import { formatNumber, getTextWidth } from "../utilities";
import { useContainerDimensions } from "../hooks/useContainerDimensions";
import BaseProgressBar from "@ramonak/react-progress-bar";
import type { ProgressBarProps as BaseProgressBarProps } from "@ramonak/react-progress-bar";
import * as colors from "../colors";

interface ProgressBarProps extends Partial<BaseProgressBarProps> {
    value: number;
    text?: string;
    fillColor?: string;
    maxValue?: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = (props: ProgressBarProps) => {
    const { value, text, fillColor, maxValue, ...rest } = props;
    const componentRef = React.useRef()
    const { width } = useContainerDimensions(componentRef);

    const progressBarPercentage = (value / maxValue) * 100;
    const progressBarText = text || formatNumber(progressBarPercentage, 1, "", "%", 1);
    const progressBarWidth = width;
    const filledWidth = progressBarWidth * value;
    const labelWidth = getTextWidth(progressBarText, "12px Helvetica Neue") + 16; // 8px padding on both sides...
    const labelIsInsideProgressBar = labelWidth < filledWidth;
    const labelAlignment = labelIsInsideProgressBar ? "right" : "left";
    const leftOffset = labelIsInsideProgressBar ? 0 : filledWidth;
    const labelClassNames = ["progressBarLabel", labelIsInsideProgressBar ? "inside" : "outside"];

    const labelCss = `.progressBarLabel { margin-left: ${leftOffset}px; }`;

    const result = (
        <div ref={componentRef}>
            <BaseProgressBar completed={value} maxCompleted={maxValue} bgColor={fillColor} customLabel={progressBarText} height="18px" labelAlignment={labelAlignment} labelClassName={labelClassNames.join(" ")} barContainerClassName="progressBarContainer" {...rest} />
        </div>
    );

    return Style.it(labelCss, result);
};

ProgressBar.defaultProps = {
    fillColor: colors.blue_2,
    maxValue: 1,
    dir: "ltr"
}

export { ProgressBar };