import React from "react";
import "./Timer.scss";
import * as colors from "../../../shared/colors";
import { describeArc } from "../../../shared/utilities";
import clsx from "clsx";

interface TimerProps {
    totalSeconds: number;
    warningSeconds?: number;
    criticalSeconds?: number;
    onTimerStarted: () => void;
    onWarningEntered: () => void;
    onCriticalEntered: () => void;
    onTimerElapsed: () => void;
    showText?: boolean;
    isPaused?: boolean;
}

type TimerState = "paused" | "started" | "warning" | "critical" | "elapsed";

function getFillColor(currentState: TimerState) {
    switch (currentState) {
        case "started": return colors.green_2;
        case "warning": return colors.orange_2;
        case "critical": return colors.red_2;
        default: return colors.grey_1;
    }
}

function Timer(props: TimerProps) {
    const { warningSeconds, criticalSeconds, onTimerStarted, onWarningEntered, onCriticalEntered, onTimerElapsed, showText, isPaused } = props;
    const [remainingSeconds, setRemainingSeconds] = React.useState(props?.totalSeconds);
    const [currentState, setCurrentState] = React.useState<TimerState>("paused");
    const [totalSeconds, setTotalSeconds] = React.useState(props?.totalSeconds);

    const timerElapsedCallback = React.useCallback(() => {
        onTimerElapsed?.();
    }, []);

    const timerStartedCallback = React.useCallback(() => {
        onTimerStarted?.();
    }, []);

    const warningEnteredCallback = React.useCallback(() => {
        onWarningEntered?.();
    }, []);

    const criticalEnteredCallback = React.useCallback(() => {
        onCriticalEntered?.();
    }, []);

    React.useEffect(() => {
        if (totalSeconds === props.totalSeconds) return;

        setTotalSeconds(totalSeconds);
        setRemainingSeconds(totalSeconds);
    }, [props.totalSeconds]);

    React.useEffect(() => {
        const tickHandler = setInterval(() => !isPaused && setRemainingSeconds(rs => Math.max(0, rs - 1)), 1000);

        return () => clearInterval(tickHandler);
    }, [isPaused, setRemainingSeconds]);

    React.useEffect(() => {
        if (remainingSeconds > warningSeconds) {
            setCurrentState("started");
        }
        else if (remainingSeconds > criticalSeconds) {
            setCurrentState("warning");
        }
        else if (remainingSeconds > 0) {
            setCurrentState("critical");
        }
        else {
            setCurrentState("elapsed");
        }
    }, [remainingSeconds, warningSeconds, criticalSeconds, setCurrentState]);

    React.useEffect(() => {
        if (currentState === "started") {
            timerStartedCallback?.();
        }
        else if (currentState === "warning") {
            warningEnteredCallback?.();
        }
        else if (currentState === "critical") {
            criticalEnteredCallback?.();
        }
        else if (currentState === "elapsed") {
            timerElapsedCallback?.();
        }
    }, [currentState, timerStartedCallback, warningEnteredCallback, criticalEnteredCallback, timerElapsedCallback]);

    const minutesString = Math.floor(remainingSeconds / 60).toString().padStart(2, "0");
    const secondsString = (remainingSeconds % 60).toString().padStart(2, "0");

    return (
        <div className="timer">
            <svg className="stopwatch-icon" viewBox="2 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={getFillColor(currentState)} d={describeArc(16, 16, 10, 0, 360 * (remainingSeconds / totalSeconds))} />
                <path d="M20.4334 11.4862C20.7555 11.8049 20.7555 12.3215 20.4334 12.6401L17.8136 15.2311C17.8857 15.4311 17.925 15.6464 17.925 15.8709C17.925 16.9223 17.0631 17.7747 16 17.7747C14.9369 17.7747 14.075 16.9223 14.075 15.8709C14.075 14.8194 14.9369 13.967 16 13.967C16.2269 13.967 16.4447 14.0059 16.6469 14.0772L19.2666 11.4862C19.5888 11.1676 20.1112 11.1676 20.4334 11.4862Z" fill="#111111" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.075 2C13.6194 2 13.25 2.36531 13.25 2.81593C13.25 3.26656 13.6194 3.63187 14.075 3.63187H15.175V5.02189C12.8265 5.19411 10.6833 6.09558 8.97596 7.49814L7.50836 6.04667C7.18618 5.72803 6.66382 5.72803 6.34164 6.04667C6.01945 6.36532 6.01945 6.88194 6.34164 7.20058L7.78867 8.63171C6.05416 10.5548 5 13.0911 5 15.8709C5 21.8793 9.92487 26.75 16 26.75C22.0751 26.75 27 21.8793 27 15.8709C27 13.0911 25.9458 10.5548 24.2113 8.63171L25.6584 7.20058C25.9805 6.88194 25.9805 6.36532 25.6584 6.04667C25.3362 5.72803 24.8138 5.72803 24.4916 6.04667L23.024 7.49814C21.3167 6.09558 19.1735 5.19411 16.825 5.02189V3.63187H17.925C18.3806 3.63187 18.75 3.26656 18.75 2.81593C18.75 2.36531 18.3806 2 17.925 2H14.075ZM16 25.1181C21.1639 25.1181 25.35 20.978 25.35 15.8709C25.35 10.7638 21.1639 6.62363 16 6.62363C10.8361 6.62363 6.65 10.7638 6.65 15.8709C6.65 20.978 10.8361 25.1181 16 25.1181Z" fill="#111111" />
            </svg>
            {showText && <div className={clsx("remaining-time-text", currentState)}>{minutesString}:{secondsString}</div>}
        </div>
    );
}

export { Timer };