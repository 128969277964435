import "dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css";
import "./Gantt.scss";
import React, { useRef, useState, useEffect } from "react";
import { Gantt, GanttConfigOptions, GanttPlugins, GanttStatic, GanttTemplates } from "dhtmlx-gantt";
import { CoreGanttItem, GanttLink, GanttTaskType, ZoomConfig } from "./types";
import { EditTaskDialog } from "./EditTaskDialog";
import * as _ from "lodash";

type GanttInternals = {
    _sort: { name: string; direction: string }
}

type GanttInputItem = {
    id: string;
    name: string;
    start: Date;
    end: Date;
    metadata?: Object;
    color?: string;
    textColor?: string;
    parent?: string;
    type?: string;
    open?: boolean;
}

type ZoomLevel = "year" | "month" | "week" | "day";

type Marker = {
    start_date: Date;
    end_date?: Date;
    css?: string;
    text?: string;
    title?: string;
}

type GanttProps = {
    input?: GanttInputItem[]; // Backwards compatibility...
    data?: GanttInputItem[];
    links?: GanttLink[];
    markers?: Marker[];
    itemHeight?: number;
    mode?: ZoomLevel;
    readOnly?: boolean;
    onItemUpdated?: (item: GanttInputItem, allItems: GanttInputItem[], ganttInstance: GanttStatic) => void;
    onItemClick?: (item: GanttInputItem, ganttInstance: GanttStatic) => boolean;
    onGanttRender?: (ganttInstance: GanttStatic) => void;
    config?: GanttConfigOptions;
    plugins?: GanttPlugins;
    templates?: GanttTemplates;
    zoomConfig?: ZoomConfig;
}

const augmentConfig = (config: GanttConfigOptions, itemHeight?: number, readOnly?: boolean, userConfig?: GanttConfigOptions) => {
    const rowHeight = itemHeight || 36;
    config.root_id = "dhtmlx-gantt-container";
    config.date_grid = "%d %M %Y"
    config.round_dnd_dates = true;
    config.auto_scheduling = false;
    config.show_progress = false;
    config.drag_links = false;
    config.row_height = rowHeight;
    config.bar_height = rowHeight - 10; // Add vertical spacing...
    config.fit_tasks = true;
    config.readonly = readOnly;

    Object.assign(config, userConfig); // Values from config object takes precedence over all default values...
}

const setTemplates = (gantt: GanttStatic & GanttInternals, templates?: GanttTemplates) => {
    Object.assign(gantt.templates, templates);
}

const setColumns = (config: GanttConfigOptions) => {
    config.columns = [
        {
            name: "text",
            label: "Name",
            tree: true,
            width: 200,
            resize: true
        }, {
            name: "start_date",
            label: "Start",
            align: "left",
            fixed: true,
            min_width: 100,
            width: 100,
            max_width: 100
        }, {
            name: "end_date",
            label: "End",
            align: "left",
            fixed: true,
            min_width: 100,
            width: 100,
            max_width: 100
        }
    ];
}

const defaultZoomConfig: ZoomConfig = {
    levels: [
        {
            name: "day",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "month", step: 1, format: "%M %Y" },
                { unit: "day", step: 1, format: "%d" }
            ]
        },
        {
            name: "week",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "week", step: 1 },
                { unit: "day", step: 1, format: "%j %D" }
            ]
        },
        {
            name: "month",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "year", step: 1, format: "%Y" },
                { unit: "month", format: "%F" }
            ]
        },
        {
            name: "quarter",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "quarter", step: 1, format: "%M %Y" },
                { unit: "month", step: 1, format: "%M" }

            ]
        },
        {
            name: "year",
            scale_height: 60,
            min_column_width: 80,
            scales: [
                { unit: "year", step: 1, format: "%Y" }
            ]
        }
    ]
};

const mapGanttItem = (item: GanttInputItem): CoreGanttItem => {
    return {
        type: (item.type as GanttTaskType) ?? GanttTaskType.Regular,
        id: item.id,
        text: item.name,
        start_date: new Date(item.start),
        end_date: new Date(item.end),
        metadata: item.metadata,
        color: item.color,
        textColor: item.textColor,
        parent: item.parent,
        open: item.open ?? true
    }
}

const reverseMapGanttItem = (item: CoreGanttItem): GanttInputItem => {
    return {
        id: item.id,
        name: item.text,
        start: item.start_date,
        end: item.end_date,
        metadata: item.metadata,
        color: item.color,
        textColor: item.textColor,
        parent: item.parent
    };
}

export default function GanttWrapper(props: GanttProps) {
    const { itemHeight, mode, onItemUpdated, onItemClick, onGanttRender, readOnly, config, plugins, templates, zoomConfig } = props;

    const [showEditDialog, setShowEditDialog] = React.useState(false);
    const [editedTask, setEditedTask] = React.useState(null);

    const ganttContainer = useRef<HTMLDivElement>();
    const ganttInstance = useRef<GanttStatic & GanttInternals>();
    const [ganttInstanceVersion, setGanttInstanceVersion] = React.useState(0);
    React.useEffect(() => {
        // Initial render, no need to rebuild the existing Gantt..
        if (!ganttInstance.current) return;

        if (
            !_.isMatch(ganttInstance.current.config, config) ||
            ganttInstance.current.config.readonly !== readOnly
        ) {
            setGanttInstanceVersion(v => v + 1);
        }
    }, [readOnly, config]);

    const attachEvents = React.useCallback(() => {
        if (onItemUpdated) {
            ganttInstance.current.attachEvent("onAfterTaskUpdate", (_id, task: CoreGanttItem) => {
                const updatedItem = reverseMapGanttItem(task);
                const allItems: GanttInputItem[] = [];
                ganttInstance.current.eachTask(t => allItems.push(reverseMapGanttItem(t)));

                onItemUpdated(updatedItem, allItems, ganttInstance.current);
            }, null);
        }

        if (onItemClick) {
            ganttInstance.current.attachEvent("onTaskClick", (id, e: Event) => {
                const task = ganttInstance.current.getTask(id);
                const item = reverseMapGanttItem(task);

                return onItemClick(item, ganttInstance.current);
            }, null);
        }

        ganttInstance.current.attachEvent("onBeforeLightbox", (id) => {
            const task = ganttInstance.current.getTask(id);
            setEditedTask(task);
            setShowEditDialog(true);
            return false; // Don't show the default edit dialog
        }, null);

    }, [ganttInstanceVersion]);

    const onTaskEdited = React.useCallback((task: CoreGanttItem) => {
        ganttInstance.current.updateTask(task.id, task);
    }, [ganttInstance.current]);

    useEffect(() => {
        let currentGanttInstance = Gantt.getGanttInstance();
        ganttInstance.current = currentGanttInstance as GanttStatic & GanttInternals;

        setColumns(ganttInstance.current.config);
        augmentConfig(ganttInstance.current.config, itemHeight, readOnly, config);
        setTemplates(ganttInstance.current, templates);
        plugins && ganttInstance.current.plugins(plugins);
        attachEvents();
        ganttInstance.current.init(ganttContainer.current);

        return () => {
            ganttInstance.current.destructor();
            ganttInstance.current = null;
        };
    }, [ganttInstanceVersion]);

    const [ganttData, setGanttData] = React.useState({ data: props?.data || props?.input || [], links: props?.links || [], markers: props?.markers || [] });
    React.useEffect(() => {
        const newData = { data: props?.data || props?.input || [], links: props?.links || [], markers: props?.markers || [] };
        if (!_.isEqual(ganttData, newData)) {
            setGanttData(newData);
        }
    }, [props.data, props.input, props.links, props.markers]);

    useEffect(() => {
        setTimeout(() => { //so that any animations finish painting first e.g. hideStageSwitch
            if (!ganttInstance.current) return;

            const scroll: { x: number, y: number } = ganttInstance.current.getScrollState();

            const { data, links, markers } = ganttData;

            ganttInstance.current.clearAll();
            ganttInstance.current.parse({ data: data.map(mapGanttItem), links: links });
            ganttInstance.current.scrollTo(scroll.x, scroll.y);
            ganttInstance.current.ext.zoom.init(zoomConfig || defaultZoomConfig);
            ganttInstance.current.ext.zoom.setLevel(mode || "year");

            if (markers && markers.length) {
                markers.forEach(marker => ganttInstance.current.addMarker(marker));
            }

            //re-sort because after parsing the gantt doesn"t reapply its current sort state
            const sortSettings = ganttInstance.current._sort;
            if (sortSettings && sortSettings.name !== "" && sortSettings.name !== null && sortSettings.name !== undefined) {
                const sortColumn = ganttInstance.current.getGridColumn(sortSettings.name);
                if (sortColumn && sortColumn.sort) {
                    if (typeof sortColumn.sort === "boolean") {
                        ganttInstance.current.sort(sortSettings.name, sortSettings.direction === "desc");
                    }
                    if (typeof sortColumn.sort !== "boolean") {
                        const sortFn = sortColumn.sort as (a: CoreGanttItem, b: CoreGanttItem) => number;
                        ganttInstance.current.sort(sortFn, sortSettings.direction === "desc");
                    }
                }
            }

            onGanttRender?.(ganttInstance.current);
        }, 0);
    }, [ganttData]);

    return (
        <div className="fill" style={{ position: "relative" }}>
            <div id="dhtmlx-gantt-container" ref={ganttContainer} className="dhtmlx-gantt-container" ></div>
            <EditTaskDialog task={editedTask} onTaskEdited={onTaskEdited} showDialog={showEditDialog} setShowDialog={setShowEditDialog} />
        </div>
    );
}
