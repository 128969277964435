import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
} from "../../../shared/components";

export interface GeoEditPolygonDialogProps {
  name: string;
  site: string;
  poiType: string;
  tat: string;
  lane: string;
  onSave: (
    name: string,
    site: string,
    poiType: string,
    tat: string,
    lane: string
  ) => void;
  onDelete: () => void;
}

export const GeoEditPolygonDialog: React.FunctionComponent<
  GeoEditPolygonDialogProps
> = (props) => {
  const [name, setName] = useState(props.name);
  const [site, setSite] = useState(props.site);
  const [poiType, setPoiType] = useState(props.poiType);
  const [tat, setTat] = useState(props.tat);
  const [lane, setLane] = useState(props.lane);

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  useEffect(() => {
    setSite(props.site);
  }, [props.site]);

  useEffect(() => {
    setPoiType(props.poiType);
  }, [props.poiType]);

  useEffect(() => {
    setTat(props.tat);
  }, [props.tat]);

  useEffect(() => {
    setLane(props.lane);
  }, [props.lane]);

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function handleChangeSite(event: React.ChangeEvent<HTMLInputElement>) {
    setSite(event.target.value);
  }

  function handleChangePoiType(event: React.ChangeEvent<HTMLInputElement>) {
    setPoiType(event.target.value);
  }

  function handleChangeTat(event: React.ChangeEvent<HTMLInputElement>) {
    setTat(event.target.value);
  }

  function handleChangeLane(event: React.ChangeEvent<HTMLInputElement>) {
    setLane(event.target.value);
  }

  function handleSave() {
    // if value left empty then it returns as NaN, change to null
    const arr = [name, site, poiType, tat, lane];
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i]) {
        arr[i] = null;
      }
    }

    props.onSave(name, site, poiType, tat, lane);
  }

  function handleDelete() {
    props.onDelete();
  }

  return (
    <>
      <DialogContent>
        <br />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleChangeName}
        />
        <br />
        <br />
        <TextField
          label="Site"
          variant="outlined"
          fullWidth
          value={site}
          onChange={handleChangeSite}
        />
        <br />
        <br />
        <TextField
          label="POI Type"
          variant="outlined"
          fullWidth
          value={poiType}
          onChange={handleChangePoiType}
        />
        <br />
        <br />
        <TextField
          label="Turn around time (mins)"
          variant="outlined"
          fullWidth
          value={tat}
          onChange={handleChangeTat}
        />
        <br />
        <br />
        <TextField
          label="Lane"
          variant="outlined"
          fullWidth
          value={lane}
          onChange={handleChangeLane}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          className="button-delete"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
