import React from 'react';
import "./Table.scss";
import GridFooter from "./GridFooter";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue, goToID, applyParameterValueChanges } from "../../../store/storyline/actions";
import { GridCellParams, GridCellEditCommitParams, GridCellValue, GridColDef, GridRenderCellParams, GridRowData, GridRowParams, GridValueFormatterParams, useGridApiRef, MuiBaseEvent, GridRenderEditCellParams, renderEditInputCell } from '@mui/x-data-grid-pro';
import { DataGrid } from "../../../shared/components";
import * as _ from "lodash";
import clsx from "clsx";
import { getColumnDateTimeFormatter, getColumnNumberFormatter } from '../../../shared/utilities';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { CellWithTooltipRenderer } from "./CellWithTooltip";

interface ParameterMap {
    fieldName: string;
    parameterName: string;
}

interface Column extends Omit<GridColDef, 'valueFormatter'> {
    index?: number;
    valueFormatter?: (params: GridValueFormatterParams) => GridCellValue | Array<string>;
    cellTemplate?: string;
    cellEditTemplate?: string;
    cellEditTemplateFieldName?: string;
    tooltipTemplate?: string;
}

interface TableProps {
    storyline: StorylineState;
    parameterMap?: ParameterMap[];
    rows: GridRowData[];
    columns: Column[];
    additionalColumns: Column[];
    navigateTo?: string;
    selectedRowParameter?: string;
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
    className?: string;
    onRowClick?: (param: GridRowParams) => void;
    hideExportToExcel?: boolean;
    allRowsParameter?: string;
    editedRowsParameter?: string;
    onRowEdited: (row: any) => void;
}

function mapValueFormatter(valueFormatter: any, columnType: string = "number"): (params: GridValueFormatterParams) => GridCellValue {
    if (_.isFunction(valueFormatter)) {
        return valueFormatter;
    }
    else if (_.isArray(valueFormatter)) {
        switch (columnType) {
            case "number": return getColumnNumberFormatter(...valueFormatter);
            case "date":
            case "dateTime": return getColumnDateTimeFormatter(...valueFormatter);
        }
    }
}

function mapCellClassName(cellClassName?: string | Function | Array<string>): (GridCellParams) => string {
    if (_.isFunction(cellClassName)) {
        return cellClassName;
    }
    if (_.isArray(cellClassName)) {
        return new Function(...cellClassName) as (GridCellParams) => string; // eslint-disable-line no-new-func
    }
    if (_.isString(cellClassName)) {
        return (_) => cellClassName;
    }

    return (_) => "";
}

function cellEditHighlighter(params: GridCellParams) {
    return clsx({ "is-dirty": _.find(params.row?.editedFields, f => f === params.field) });
}

function getFieldValueSetterForRow(gridApi: GridApiPro, row: any) {
    return (field: string, value: any) => {
        gridApi.setEditRowsModel({ [row.id]: { [field]: { value: value } } });
        gridApi.commitCellChange({ id: row.id, field: field }, new Event("selectionchange"));
        gridApi.setCellMode(row.id, field, "view");
    }
}

const { TemplateRenderer } = require("../TemplateRenderer");

function mapColumn(column: Column): GridColDef {
    // Dynamically load the TemplateRenderer component in order to work around the cyclic dependency...
    const { valueFormatter, type, renderCell, cellTemplate, tooltipTemplate, cellClassName, cellEditTemplate, cellEditTemplateFieldName } = column;
    const RenderCellTemplate = (params: GridRenderCellParams) => params.cellMode === "view" ? <TemplateRenderer data={{ ...params.row, row: params.row, gridApi: params.api, setFieldValue: getFieldValueSetterForRow(params.api, params.row), params }} template={cellTemplate} /> : null;
    const RenderCellEditTemplate = (params: GridRenderEditCellParams) =>
        cellEditTemplate ? <TemplateRenderer data={{ ...params.row, row: params.row, gridApi: params.api, params }} template={cellEditTemplate ?? ""} /> :
        cellEditTemplateFieldName && params?.row?.[cellEditTemplateFieldName] ? <TemplateRenderer data={{ ...params.row, row: params.row, gridApi: params.api, params }} template={params?.row?.[cellEditTemplateFieldName] ?? ""} /> :
        renderEditInputCell(params);
    const RenderTooltipTemplate = (params: GridRenderCellParams) => params.cellMode === "view" ? <TemplateRenderer data={{ ...params.row, row: params.row, params }} template={tooltipTemplate} /> : null;
    const cellRenderer = renderCell ? renderCell : cellTemplate ? RenderCellTemplate : null;
    const tooltipRenderer = tooltipTemplate ? (params: GridRenderCellParams) => <CellWithTooltipRenderer tooltipRenderer={RenderTooltipTemplate} cellRenderer={cellRenderer} {...params} /> : null;
    const cellEditRenderer = (cellEditTemplate || cellEditTemplateFieldName) ? RenderCellEditTemplate : undefined;
    const classNameHandler = mapCellClassName(cellClassName);
    const combinedCellClassName = (params: GridCellParams) => clsx(cellEditHighlighter(params), classNameHandler(params));

    let result = {
        ...column,
        valueFormatter: mapValueFormatter(valueFormatter, type),
        renderCell: tooltipRenderer ? tooltipRenderer : cellRenderer,
        cellClassName: combinedCellClassName
    };

    // For some reason setting `renderEditCell` to null/undefined breaks editing for all fields, so we need to omit the field altogether instead...
    if (cellEditRenderer) result.renderEditCell = cellEditRenderer;

    return result;
}

function Table(props: TableProps) {
    const { storyline, parameterMap, selectedRowParameter, parameterValues, updateParameterValue, navigateTo, rows, columns, additionalColumns, className, allRowsParameter, editedRowsParameter, onRowEdited, ...other } = props;
    const [allColumns, setAllColumns] = React.useState(columns);
    const [currentRows, setCurrentRows] = React.useState(rows);
    const apiRef = useGridApiRef();

    React.useEffect(() => {
        const result = columns?.map(c => mapColumn(c));
        _.forEach(additionalColumns, additionalColumn => {
            result.splice(additionalColumn.index, 0, mapColumn(additionalColumn));
        });
        setAllColumns(result);

    }, [columns, additionalColumns]);

    React.useEffect(() => {
        if (currentRows?.length === rows?.length && _.isMatch(currentRows, rows)) return;
        setCurrentRows(rows);

        // Clear out the edited rows parameter when the grid data is refreshed...
        if (editedRowsParameter) {
            updateParameterValue(editedRowsParameter, []);
        }
    }, [rows]);

    const onRowClick = React.useCallback(
        (e: GridRowParams) => {
            const data = e.row;

            if (parameterMap?.length && navigateTo) {
                const parameters = _.map(parameterMap, map => `${encodeURIComponent(map.parameterName)}=${encodeURIComponent(data[map.fieldName])}`);

                window.open(`${navigateTo}?${parameters.join("&")}`, "_blank");
            }
            else if (selectedRowParameter) {
                updateParameterValue(selectedRowParameter, data);
            }

            return () => null;
        },
        [parameterMap, updateParameterValue]
    );

    const onCellEditCommit = React.useCallback(
        (params: GridCellEditCommitParams, event?: MuiBaseEvent) => {
            const { id, field, value } = params;
            const model = apiRef.current.getRowModels().get(id); // The current value of the row being edited...

            // If the value hasn't actually changed OR we haven't triggered this via a setFieldValue call, skip the below...
            if (_.isEqual(value, model[field]) && (event as Event)?.type !== "selectionchange") {
                return;
            }

            model.editedFields = _.uniq([...(model?.editedFields || []), field]);
            const editedRow = { ...model, [field]: value }; // The data that will be committed

            if (onRowEdited) {
                onRowEdited(editedRow);
            }

            if (editedRowsParameter) {
                const editedRows = parameterValues.get(editedRowsParameter) || [];
                // If this row was edited previously, exclude the old version from the changeset and only include the latest version...
                const editedRowsExcludingCurrentRow = editedRows.filter(r => r.id !== id);
                updateParameterValue(editedRowsParameter, [...editedRowsExcludingCurrentRow, editedRow]);
            }

            if (!allRowsParameter) return;
            setTimeout(() => {
                const gridRows = Array.from(apiRef.current.getRowModels().entries()).map(([_, item]) => item);
                updateParameterValue(allRowsParameter, gridRows);
            }, 50);
        },
        [apiRef, parameterValues, updateParameterValue, onRowEdited]
    );

    return (
        <DataGrid
            apiRef={apiRef}
            {...other}
            className={clsx("table-component", navigateTo && "navigate-on-click", className)}
            components={{
                Footer: GridFooter
            }}
            componentsProps={{
                footer: { hideExportToExcel: props.hideExportToExcel }
            }}
            columns={allColumns}
            rows={currentRows || []}
            onCellEditCommit={onCellEditCommit}
            onRowClick={props.onRowClick || onRowClick}
        />
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any, goToID: goToID as any, applyParameterValueChanges: applyParameterValueChanges as any })(Table);