import { createContext, useContext } from "react";

export const CanvasBindingsContext: React.Context<object> = createContext(null);
export const useCanvasBindings: () => object = () => useContext(CanvasBindingsContext);
export const CanvasBindingsProvider = ({
    bindings,
    children
}: { bindings: object, children: any }) => (
    <CanvasBindingsContext.Provider value={bindings}>
        {children}
    </CanvasBindingsContext.Provider>
);