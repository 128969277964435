import * as api from "../../shared/api-client"

export interface BreadcrumbItem {
    id?: string;
    title?: string;
    url?: string;
    children?: BreadcrumbItem[];
}

export interface AppState {
    loading: boolean;
    menuItems: api.MenuItemDisplayModel[];
    userMetadata: object;
    menuIsExpanded: boolean;
    breadcrumbStack: BreadcrumbItem[];
}

export const LOAD_APP = "LOAD_APP";
export const SHOW_APP = "SHOW_APP";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const SET_MENU_IS_EXPANDED = "SET_MENU_IS_EXPANDED";
export const SET_BREADCRUMB_STACK = "SET_BREADCRUMB_STACK";

interface LoadAppAction {
    type: typeof LOAD_APP
}

interface ShowAppAction {
    type: typeof SHOW_APP,
    menuItems: api.MenuItemDisplayModel[],
    userMetadata: object
}

interface ToggleMenuAction {
    type: typeof TOGGLE_MENU;
}

interface SetBreadcrumbStackAction {
    type: typeof SET_BREADCRUMB_STACK;
    breadcrumbStack: BreadcrumbItem[];
}

interface SetMenuIsExpandedAction {
    type: typeof SET_MENU_IS_EXPANDED;
    value: boolean;
}

export type AppActionTypes = LoadAppAction | ShowAppAction | ToggleMenuAction | SetBreadcrumbStackAction | SetMenuIsExpandedAction;