import React from 'react';
import "./DatePicker.scss";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { DatePicker as BaseDatePicker } from "../../../shared/components";
import { Moment, default as moment } from 'moment';

interface Props {
    storyline: StorylineState;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    format?: string;
    minDate?: string | Date | Moment;
    maxDate?: string | Date | Moment;
    onChange?: (value: Moment) => void
}

export function DatePicker(props: Props) {
    const { storyline, updateParameterValue, name, format, minDate, maxDate, onChange, ...other } = props;
    const [value, setValue] = React.useState(storyline.parameterValues.get(name) || null);

    const handleChange = (newValue: Moment) => {
        updateParameterValue(name, newValue);
        setValue(newValue);
        onChange?.(newValue);
    };

    React.useEffect(() => {
        if (storyline.parameterValues.has(name)) {
            const newValue = storyline.parameterValues.get(name);
            if (newValue !== value) {
                setValue(newValue);
            }
        }
    }, [storyline.parameterValues]);

    return (
        <BaseDatePicker
            className="date-picker"
            {...other}
            minDate={minDate ? moment(minDate) : undefined}
            maxDate={maxDate ? moment(maxDate) : undefined}
            value={value}
            onChange={handleChange}
            inputFormat={format || "YYYY/MM/DD"}
        />
    );
}

export default connect(
    (state: RootState) => ({
        storyline: state.storyline
    }),
    { updateParameterValue: updateParameterValue as any })(DatePicker);