import React from "react";
import { Avatar, IconButton, MenuItem, ListItemIcon, Menu } from "../../shared/components";
import { Settings, Avatar as UserIcon } from "../../shared/components/icons";
import { useAuth0 } from "../../auth/AuthContext";
import { Link } from "react-router-dom";
import { ROLES } from "../../auth/types";

export default function AdminMenuButton(props) {
    const { roles } = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        roles?.includes?.(ROLES.ADMINISTRATOR) &&
        <>
            <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar className="bg-secondary">
                    <Settings color="white" />
                </Avatar>
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link to="/admin/users">
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon><UserIcon /></ListItemIcon>
                        Manage Users
                </MenuItem>
                </Link>
            </Menu>
        </>
    );
}