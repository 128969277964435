import * as React from "react";
import "./FileUpload.scss";
import { Button } from "../../../shared/components";

interface FileUploadProps {
    onFilesSelected?: (files: File[]) => void;
    buttonContent?: any;
}

function FileUpload(props: FileUploadProps) {
    const { onFilesSelected, buttonContent, ...rest } = props;
    const inputRef = React.useRef<HTMLInputElement>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files.length < 1) return;

        onFilesSelected?.([...files]);
    }

    return (
        <div className="file-upload-container">
            <input type="file" ref={inputRef} onChange={handleChange} {...rest} />
            <Button variant="outlined" size="small" onClick={() => { inputRef.current.click(); }}  {...rest}>
                { buttonContent ?? "Select File" }
            </Button>
        </div>
    );
}

export { FileUpload }