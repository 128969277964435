import React from "react";
import "./AjaxLoadingIndicator.scss";
import { useSelector } from 'react-redux';
import { CircularProgress } from "./";
import { RootState } from "../../store";

export function AjaxLoadingIndicator(props) {
    const outstandingRequestCount = useSelector((store: RootState) => store.notifications.outstandingRequestCount || 0);

    return outstandingRequestCount > 0 ?
        <div className="ajax-loading">
            <CircularProgress />
        </div> :
        null;
}