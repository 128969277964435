/* eslint-disable eqeqeq */
import React from 'react';
import "./Autocomplete.scss";
import * as _ from "lodash";
import { StorylineState } from "../../../store/storyline/types";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { updateParameterValue } from "../../../store/storyline/actions";
import { CircularProgress, InputAdornment, Autocomplete as BaseAutocomplete, TextField, Popper, Paper, TreeView, TreeViewNode } from "../../../shared/components";
import { BehaviorSubject } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map, filter, switchMap, debounceTime, tap } from 'rxjs/operators';

export interface Option {
    value: any;
    label: string;
    children?: Option[];
}

interface Props {
    parameterValues: Map<string, any>;
    updateParameterValue: typeof updateParameterValue;
    name: string;
    options?: Option[];
    label?: string;
    multiple?: boolean;
    hierarchical?: boolean;
    fetchUrl?: string;
    highlightMatchPattern?: "prefix" | "wildcard";
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLDivElement>, value: Option | Option[]) => void;
}

const subject$ = new BehaviorSubject("");

export function getFullHierarchy(option: Option): Option[] {
    const allChildren = _.flatMap(option.children, getFullHierarchy);
    return [option, ...allChildren];
}

function mapOptionToTreeNode(option: Option): TreeViewNode {
    return {
        id: option.value,
        title: option.label,
        children: option.children?.map(mapOptionToTreeNode)
    };
}

function mapTreeNodeToOption(node: TreeViewNode): Option {
    return {
        value: node.id,
        label: node.title
    };
}

export function Autocomplete(props: Props) {
    const { parameterValues, multiple, options, name, onChange, fetchUrl, updateParameterValue, label, placeholder, hierarchical, ...other } = props;

    const inputRef = React.useRef<HTMLDivElement>(null);

    const [availableOptions, setAvailableOptions] = React.useState<Option[]>(options || []);
    const treeNodes = React.useMemo(() => (options || []).map(mapOptionToTreeNode), [options]);
    const [treeViewPopperBeacon, setTreeViewPopperBeacon] = React.useState(0);

    const [value, setValue] = React.useState<Option | Option[]>(
        multiple ?
            options?.filter(o => parameterValues?.get(name)?.find(pv => pv === o?.value)) ?? [] :
            options?.find(o => o?.value == parameterValues?.get(name)) ?? null
    );
    const [inputValue, setInputValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleChange = (event: any, newValue: Option | Option[]) => {
        setValue(newValue);

        // Multi-select parameter value changes should be handled as a batch when the overlay is closed...
        if (open && multiple) return;

        const newParameterValue = newValue instanceof Array ? newValue.map(v => v.value) : newValue?.value;
        name && updateParameterValue(name, newParameterValue);

        if (onChange) {
            onChange(event, newValue);
        }
    };

    const handleClose = () => {
        setOpen(false);

        if (multiple) {
            name && updateParameterValue(name, (value as Option[]).map(v => v.value));
        }
    };

    React.useEffect(() => {
        setAvailableOptions(options || []);
    }, [options]);

    React.useEffect(() => {
        if (parameterValues.has(name)) {
            const newValue = parameterValues.get(name);
            if (options) {
                const availableOptions: Option[] = hierarchical ? options.flatMap(getFullHierarchy) : options;
                if (multiple) {
                    setValue(_.filter(availableOptions, option => newValue?.find?.(v => v == option?.value) != null));
                }
                else if (newValue != (value as Option)?.value) {
                    const selectedOption = _.find(availableOptions, option => option?.value == newValue);
                    setValue(selectedOption);
                    onChange && onChange({} as React.ChangeEvent<any>, selectedOption);
                }
            } else {
                if (multiple) {
                    const newOptions = _.map(newValue, v => ({ value: v, label: `${v}` }))
                    setAvailableOptions(newOptions);
                    setValue(newOptions);
                }
                else {
                    if (newValue !== null && newValue !== undefined) {
                        const newOption = { value: newValue, label: `${newValue}` };
                        setAvailableOptions([newOption]);
                        setValue(newOption);
                    }
                    else {
                        setAvailableOptions([]);
                        setValue(null);
                    }
                }
            }
        }
    }, [parameterValues, options]);

    const getSuggestions = (subject: BehaviorSubject<string>) => {
        return subject.pipe(
            debounceTime(100), // wait until user stops typing
            filter((text: string) => text.length > 0), // send request only if search string is not empty
            map((text: string) => fetchUrl.replace("{searchTerm}", encodeURIComponent(text))), // form url for the API call
            tap(() => setLoading(true)), // show loading indicator
            switchMap((url: string) => ajax(url)), // call HTTP endpoint and cancel previous requests
            map(({ response }: AjaxResponse<any[]>) => response), // change response shape for autocomplete consumption
            tap(() => setLoading(false)) // hide loading indicator
        );
    };

    React.useEffect(() => {
        const subscription = getSuggestions(subject$).subscribe(
            suggestions => {
                setAvailableOptions(suggestions);
            },
            error => {
                // Ignore the error for now - perhaps show an icon in the text field going forward?
            }
        );

        return () => subscription.unsubscribe();
    }, [fetchUrl]);

    React.useEffect(() => {
        if (fetchUrl && inputValue !== "" && inputValue != (value as Option)?.value) {
            subject$.next(inputValue);
        }
    }, [inputValue]);

    const selectedNodeIds = React.useMemo(() => _.isArray(value) ? value?.map(v => v.value) : value?.value, [value]);
    const TreeViewPopper = React.useCallback((props) => {
        const { open, anchorEl, ...rest } = props;

        return <Popper {...props}>
            <Paper className="col-fill" onMouseDown={(event) => {
                // Prevent input blur when interacting with the TreeView content
                event.preventDefault();
            }}>
                <ul className="MuiAutocomplete-listbox">
                    <TreeView
                        nodes={treeNodes}
                        defaultSearchTerm={inputValue}
                        defaultSelected={selectedNodeIds}
                        multiSelect={multiple}
                        multiSelectWithoutCtrl={true}
                        onSelectionChanged={(selectedNodes) => {
                            if (multiple) {
                                setValue(selectedNodes?.map(mapTreeNodeToOption));
                            } else {
                                setValue(mapTreeNodeToOption(selectedNodes?.[0]));
                                name && updateParameterValue(name, selectedNodes?.[0]?.id);
                                setOpen(false);
                                setTimeout(() => Array.from(inputRef?.current?.getElementsByTagName("input")).forEach(e => e?.blur()));
                            }
                        }}
                    />
                </ul>
            </Paper>
        </Popper>
    }, [treeViewPopperBeacon]);

    return (
        <BaseAutocomplete
            size="small"
            {...other}
            multiple={!!multiple}
            options={availableOptions}
            noOptionsText={(fetchUrl && !inputValue) ? "Enter text to search for options." : "No options available."}
            value={value}
            onChange={handleChange}
            onOpen={() => {
                setOpen(true);
                if (hierarchical) {
                    setTreeViewPopperBeacon(v => v + 1);
                    !multiple && setInputValue("");
                }
            }}
            open={open}
            onClose={handleClose}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                // Prevent selection from clearing out the existing search text...
                if (event) {
                    hierarchical && setTreeViewPopperBeacon(v => v + 1);
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    ref={inputRef}
                    label={label}
                    variant="outlined"
                    placeholder={multiple && (value as Option[])?.length > 0 ? "" : placeholder}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </InputAdornment>
                        )
                    }}
                />
            )}
            PopperComponent={hierarchical ? TreeViewPopper : null}
        />
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues
    }),
    { updateParameterValue: updateParameterValue as any })(Autocomplete);